import React, { ReactNode } from 'react';

import { Layout } from 'antd';
import SiderMain from './Sider';
import HeaderMain from './Header';

const { Header, Content, Sider } = Layout;

type Props = {
  children?: ReactNode;
};

function LayoutMain(props: Props) {
  const { children } = props;

  return (
    <Layout className="layout" style={{ overflow: 'hidden' }}>
      <HeaderMain />
      <Layout>
        <Sider
          width={200}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            // left: 24,
            top: 80,
            zIndex: 1,
            boxShadow: '0px 1px 20px rgb(18 34 97 / 20%)',
          }}
        >
          <SiderMain />
        </Sider>
        <Layout
        // style={{ padding: '0 24px' }}
        >
          <Content
            style={{
              margin: 0,
              width: '100%',
              minHeight: '100vh',
              overflow: 'auto',
            }}
          >
            <Header />
            <Layout>
              <Sider width={200} />
              <Layout
              // style={{ padding: '0 24px' }}
              >
                <Content
                  style={{
                    // padding: 24,
                    margin: 0,
                    width: '100%',
                    // minHeight: '100vw',
                  }}
                >
                  {children}
                </Content>
              </Layout>
            </Layout>
            {/* <Footer /> */}
            {/* <Footer style={{ zIndex: 10 }}>Ant Design ©2018 Created by Ant UED</Footer> */}
          </Content>
        </Layout>
      </Layout>
      {/* <Footer style={{
            textAlign: 'center',
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
            zIndex: 1,
        }}>Ant Design ©2018 Created by Ant UED</Footer> */}
    </Layout>
  );
}

export default LayoutMain;
