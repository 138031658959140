import React, { useState, useEffect } from 'react';
import { Button, Layout, Row, Col, Drawer } from 'antd';
import { useParams } from 'react-router-dom';
import { digitalCertificateApi } from '@services/api';
import PDFViewer from 'pdf-viewer-reactjs';
import DigitalCertificateSignInfo from '@components/digital-certificate/DigitalCertificateSignInfo';
import CustomNavigation from '@components/pdf/NavigatePdf';
import { CSSTransition } from 'react-transition-group';
import {
  InfoCircleOutlined,
  InfoCircleFilled,
  DownloadOutlined,
} from '@ant-design/icons';
import { DigitalCertificateInfoById } from '@services/models/digital-certificate-model';
import * as theme from '@theme';
import { ORIENTATION } from '@constants/constants';

function DigitalCertificateInfo() {
  // let history = useHistory();

  // 2dfdbfea-7450-4a61-9f64-88533c00c864

  const { id }: any = useParams();

  const [docInfo, setDocInfo] = useState<DigitalCertificateInfoById.Response>();
  const [summary, setSummary] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [scale, setScale] = useState(1.2);

  // useEffect(() => {
  //   if (docInfo) {
  //     console.log('XXX', docInfo);
  //   }
  // }, [docInfo]);

  useEffect(() => {
    window.addEventListener('resize', renderPdfSize);
    return () => {
      window.removeEventListener('resize', renderPdfSize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      getDoc(id);
    }
    renderPdfSize();
    // eslint-disable-next-line
  }, []);

  function renderPdfSize() {
    const width: number = window.innerWidth;
    // console.log('more', scale);
    if (width < 300) {
      if (scale !== 0.4) {
        setScale(0.4);
      }
    } else if (width < 600) {
      if (scale !== 0.5) {
        setScale(0.5);
      }
    } else if (width < 800) {
      if (scale !== 0.8) {
        setScale(0.8);
      }
    } else {
      if (scale !== 1.5) {
        setScale(1.5);
      }
    }
  }

  async function getDoc(docId: string) {
    try {
      const result = await digitalCertificateApi.digitalCertificateInfoById(
        docId
      );
      if (result) {
        setDocInfo(result);
      }
    } catch (error) {
      throw error;
    }
  }

  function downloadPdf() {
    if (docInfo) {
      window.open(docInfo.info.docPublicUrl, '_blank');
    }
  }

  function changeSummary() {
    setSummary(!summary);
  }

  function changeDrawer() {
    setDrawer(!drawer);
  }

  return (
    <>
      <Layout style={{ backgroundColor: '#525461' }}>
        <Row>
          <Col xs={24} md={0} lg={0} xl={0}>
            <div
              className="wordBreak"
              style={{
                position: 'fixed',
                backgroundColor: `${theme['@primary-color']}`,
                height: '50px',
                width: '100%',
                lineHeight: '50px',
                zIndex: 100,
                padding: '0px 10px 0px 10px',
                textAlign: 'right',
              }}
            >
              <Button
                style={{
                  border: '1px',
                  borderRadius: '20px',
                  backgroundColor: 'transparent',
                  color: '#fff',
                }}
                onClick={downloadPdf}
              >
                <DownloadOutlined /> Download
                </Button>
              <div className="displayInlineBlock">
                <a onClick={changeDrawer}>
                  <InfoCircleFilled
                    style={{ color: `${theme['@secondary-color']}` }}
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col xs={0} md={24} lg={24} xl={0}>
            <div
              className="wordBreak"
              style={{
                position: 'fixed',
                backgroundColor: `${theme['@primary-color']}`,
                height: '50px',
                width: '100%',
                lineHeight: '50px',
                zIndex: 100,
                padding: '0px 10px 0px 10px',
                textAlign: 'right',
              }}
            >
              <div className="displayInlineBlock pr10">
                <img src="/images/sign_icon.svg" alt="" />
              </div>
              <div
                className="displayInlineBlock responsive pr10"
                style={{ color: '#fff' }}
              >
                {'Signed and all signatures are valid.'}
              </div>
              <div className="displayInlineBlock">
                <Button
                  style={{
                    border: '1px solid #fff',
                    borderRadius: '10px',
                    backgroundColor: 'transparent',
                    color: '#fff',
                  }}
                  onClick={changeDrawer}
                >
                  <div className="responsive">
                    <InfoCircleOutlined /> Summary
                  </div>
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={0} md={0} lg={0} xl={24}>
            <div
              style={{
                position: 'fixed',
                backgroundColor: `${theme['@primary-color']}`,
                height: '50px',
                width: '100%',
                lineHeight: '50px',
                zIndex: 100,
                padding: '0px 10px 0px 10px',
                textAlign: 'right',
              }}
            >
              <div className="displayInlineBlock">
                <Button
                  style={{
                    border: '1px solid #fff',
                    borderRadius: '10px',
                    backgroundColor: 'transparent',
                    color: '#fff',
                  }}
                  onClick={changeSummary}
                >
                  <InfoCircleOutlined /> Summary
                </Button>
                <Button
                  style={{
                    border: '1px',
                    borderRadius: '20px',
                    backgroundColor: 'transparent',
                    color: '#fff',
                  }}
                  onClick={downloadPdf}
                >
                  <DownloadOutlined /> Download
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            xs={24}
            md={24}
            lg={24}
            xl={summary ? 18 : 24}
            style={{ textAlign: 'center', paddingTop: '50px' }}
          >
            {docInfo?.info?.docPublicUrl ? (
              <PDFViewer
                document={{ url: docInfo?.info.docPublicUrl }}
                css="customViewer"
                scale={scale}
                maxScale={2.0}
                minScale={0.5}
                hideRotation={true}
                rotationAngle={docInfo.certificate.orientation == ORIENTATION.LANDSCAPE ? 90 : 0}
                // rotationAngle={90}
                navbarOnTop={true}
                navigation={CustomNavigation}
              />
            ) : (
              <div style={{ height: '100vh', color: '#fff' }}>No File</div>
            )}
          </Col>
          <CSSTransition
            in={summary}
            timeout={3000}
            classNames="alert"
            unmountOnExit
          >
            <Col
              xs={0}
              md={0}
              lg={0}
              xl={summary ? 6 : 0}
              style={{ backgroundColor: '#fff' }}
            >
              <DigitalCertificateSignInfo data={docInfo} />
            </Col>
          </CSSTransition>
        </Row>

        <Drawer
          width={window.innerWidth > 400 ? 400 : '80%'}
          placement="right"
          closable={false}
          onClose={() => {
            setDrawer(false);
          }}
          visible={drawer}
          bodyStyle={{ padding: '0px' }}
          zIndex={10}
        >
          <DigitalCertificateSignInfo data={docInfo} />
        </Drawer>
      </Layout>
    </>
  );
}

export default DigitalCertificateInfo;
