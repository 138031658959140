import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { dateStringToDatetime } from '@utils/date-utils';
import { DigitalCertificateInfoById } from '@services/models/digital-certificate-model';

type Props = {
  data?: DigitalCertificateInfoById.Response;
};

function DigitalCertificateSignInfo(props: Props) {
  const { data } = props;

  const [docInfo, setDocInfo] = useState<DigitalCertificateInfoById.Response>();

  useEffect(() => {
    if (data) {
      setDocInfo(data);
    }
  }, [data]);

  function getName(item: any, length: number) {
    if (item) {
      if (item === 'I AM Consulting') {
        return item.slice(0, 4).toUpperCase();
      }else return item.slice(0, length).toUpperCase();
      // else {
      //     var name = (item?.firstNameEn[0] ?? '').concat(item?.lastNameEn[0] ?? '');
      //     return name.toUpperCase()
      // }
    }
    return '';
  }

  return (
    <>
      <Row>
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div
            style={{
              padding: '60px 10px 10px 10px',
              backgroundColor: '#BFC6DC',
            }}
            className="topicText"
          >
            Summary
          </div>
          <div className="height10" />
        </Col>
        <Col xs={24} className="pt10 pb10 pl20 pr20">
          <div className="secondText">Certificate Of Completion</div>
          <div className="height10" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">{'Certificate ID'}</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {data?.certificate?.id ?? '-'}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">{'Certificate Name'}</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {docInfo?.certificate?.studentCode + "_" + docInfo?.certificate?.certificateName + ".pdf"}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
        </Col>
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div className="height10" />
        </Col>{' '}
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div className="height10" />
        </Col>{' '}
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div className="height10" />
        </Col>{' '}
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div className="height10" />
        </Col>
        <Col xs={24} className="pt10 pb10 pl20 pr20">
          <div className="secondText">Signer Information</div>
          <div className="height5" />
        </Col>
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div className="height5" />
        </Col>
        <Col
          xs={24}
          className="pt5 pb10 pl20 pr20 font12"
          style={{ backgroundColor: '#E2E5ED', color: '#9CA2B5' }}
        >
          <Row>
            <Col xs={24} className="pr10">
              {docInfo?.certificate.signedByName}
            </Col>
            <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
              <div className="height5" />
            </Col>
          </Row>
        </Col>
        <Col xs={24} className="pt10 pb10 pl20 pr20">
          <Row>
            <Col xs={24} className="pr10">
              <div className="box40 bgGreen circle displayInlineBlock">
                <div className="center labelGreen font14 boldText">
                  {getName(docInfo?.certificate.signedByName, 2)}
                </div>
              </div>
              <div className="responsive boldText pl10 pr10 displayInlineBlock">
                {docInfo?.certificate.signedByName}
              </div>

              <div className="box40 displayInlineBlock floatRight">
                <div className="center responsive labelSign">
                  <div className="floatRight">{'Signed'}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="height5" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">Signed</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {docInfo?.certificate.signedDate
                  ? dateStringToDatetime(docInfo?.certificate.signedDate, 'en')
                  : '-'}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
        </Col>
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div className="height5" />
        </Col>
      </Row>
    </>
  );
}

export default DigitalCertificateSignInfo;
