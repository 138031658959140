import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button, PageHeader, Row, Col, Input, Form, notification } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import LayoutMain from '@components/layout/Layout';
import { userApi } from '@services/api';
import { Entity } from '@services/models/user-model';
import * as userLoginUtils from '@utils/user-login-utils';

const routes = [
  {
    path: '/users',
    breadcrumbName: 'Users',
  },
  {
    path: '#',
    breadcrumbName: 'Edit Customer',
  },
];

function EditUserPage() {
  const { id }: any = useParams();
  const [userForm] = Form.useForm();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<Entity>();

  const userInfo = userLoginUtils.getUserInfo();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const initData = useCallback(async () => {
    try {
      setIsLoading(true);
      const userResponse: Entity = await userApi.getById(id);
      setUser(userResponse);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    initData();
  }, [initData]);

  const mapfieldValuetoForm = useCallback(async () => {
    if (user) {
      userForm.setFields(
        Object.entries(user).map(([key, value]) => {
          // moment value need to assign here before send back to form
          return {
            name: key,
            value,
          };
        })
      );
    }
  }, [user, userForm]);

  useEffect(() => {
    mapfieldValuetoForm();
  }, [mapfieldValuetoForm]);

  const onFinish = async (values: any) => {
    // console.log('Finish:', values);
    try {
      setIsLoading(true);
      const payload = {
        ...values,
        id,
        updateBy: userInfo?.id,
      };
      await userApi.updateCustomer(payload);
      notification.success({
        message: 'Your customer was sucessfully edited.',
      });
      history.push('/users');
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message:
          'Sorry, we cannot edit your customer right now. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LayoutMain>
      <PageHeaderStyle
        title={user ? `${user.firstNameEn} ${user.lastNameEn}` : '-'}
        onBack={() => window.history.back()}
        breadcrumb={{
          routes,
          itemRender: (route: any) => (
            <Link to={route.path}>{route.breadcrumbName}</Link>
          ),
        }}
      />
      <ContentStyle>
        <Form {...layout} form={userForm} onFinish={onFinish}>
          <ContentHeaderStyle>Personal Information</ContentHeaderStyle>

          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="First Name (EN)"
                name="firstNameEn"
                rules={[
                  {
                    required: true,
                    message: 'First Name (EN) is required',
                  },
                  {
                    pattern: /^[^ก-๙-ูเ-ํ]+$/,
                    message: 'First Name (EN) must be in english',
                  },
                  {
                    max: 255,
                    message:
                      'First Name (EN) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Last Name (EN)"
                name="lastNameEn"
                rules={[
                  {
                    required: true,
                    message: 'Last Name (EN) is required',
                  },
                  {
                    pattern: /^[^ก-๙-ูเ-ํ]+$/,
                    message: 'Last Name (EN) must be in english',
                  },
                  {
                    max: 255,
                    message:
                      'Last Name (EN) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="First Name (TH)"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'First Name (TH) is required',
                  },
                  {
                    pattern: /^[ก-ูเ-ํ ]+$/,
                    message: 'First Name (TH) must be in thai',
                  },
                  {
                    max: 255,
                    message:
                      'First Name (TH) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Last Name (TH)"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Last Name (TH) is required',
                  },
                  {
                    pattern: /^[ก-ูเ-ํ ]+$/,
                    message: 'Last Name (TH) must be in thai',
                  },
                  {
                    max: 255,
                    message:
                      'Last Name (TH) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Telephone Number"
                name="telephone"
                rules={[
                  {
                    required: true,
                    message: 'Telephone Number is required',
                  },
                  {
                    pattern: /^[0-9]{10,10}$/,
                    message: 'Telephone Number must be 10 digits',
                  },
                ]}
              >
                <Input placeholder="0XX-XXX-XXXX" style={{ width: '50%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={[32, 0]}
            style={{ justifyContent: 'flex-end', marginTop: '24px' }}
          >
            <Col>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                style={{ padding: '0px 50px' }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </ContentStyle>
    </LayoutMain>
  );
}

const PageHeaderStyle = styled(PageHeader)`
  padding: '24px';

  .ant-page-header-heading
    > .ant-page-header-heading-left
    > .ant-page-header-heading-title {
    line-height: 40px;
  }
`;
const ContentHeaderStyle = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 60px;

  margin-bottom: 20px;
  border-bottom: 1px solid #e2e5ed;
  color: #525461;
`;

const ContentStyle = styled.div`
  margin: 24px;
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(227, 228, 235, 0.25);
`;

export default EditUserPage;
