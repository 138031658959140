import { api } from '@core/api/api-client';

import * as ENDPOINT from '../endpoint';
import { DocumentInfoById } from '../models/document-model';

export async function documentInfoById(docId: string) {
  const response = await api
    .get<DocumentInfoById.Response>(`${ENDPOINT.DOC_INFO}/${docId}`)
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return response.data;
}

// export async function getInvestorList(
//     projectId: string,
//     options?: Partial<InvestorList.Request>
//   ) {
//     const response = await axios.post<InvestorList.Response>(
//       ENDPOINT.INVESTOR_LIST,
//       {
//         projectId,
//         limit: {
//           pageNumber: 1,
//           pageSize: 100
//         },
//         ...(options || {})
//       }
//     )
//     return response.data
//   }
