import { RcFile } from 'antd/lib/upload';
import { AxiosResponse } from 'axios';

export function defaultFileTypeSupported(file: RcFile, fileList: RcFile[]) {
  // Supported file: .xls,xlsx,pdf,jpg,jpeg,png
  if (file) {
    if (
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      // xlsx
      return true;
    }
    // if (file.type === "application/vnd.ms-excel") {
    //   //.xls
    //   return true;
    // }
    if (file.type === 'application/pdf') {
      return true;
    }
    if (file.type === 'image/jpg') {
      return true;
    }
    if (file.type === 'image/jpeg') {
      return true;
    }
    if (file.type === 'image/png') {
      return true;
    }
  }
  return false;
}

/**
 * @example "292397bd-b6e3-4fa5-b24c-0085843ff243_NapatKOld.png" -> "png"
 */
export function fileExtension(filename: string) {
  const parts = filename.split('.');
  return parts[parts.length - 1];
}

//////////////////////////////////////////// Get file from url //////////////////////////////////////////
export async function getFileFromURL(
  url: string,
  fileName: string,
  fileType: string
) {
  // console.log('type: ', fileType.substr(fileType.indexOf('/') + 1))
  // fileName = fileName;
  // let metadata = {};

  // if (fileType === "jpg" || fileType === "jpeg" || fileType === "png") {
  //   metadata = { type: "image/" + fileType };
  // } else if (fileType === "pdf") {
  //   metadata = { type: "application/pdf" };
  // }

  const file = await fetch(url)
    .then((r) => r.blob())
    .then((blobFile) => new File([blobFile], fileName, { type: fileType }));
  // console.log("File: ", file);
  return file;
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////

export function downloadFile(response: AxiosResponse, fileName?: string) {
  // console.log(response);

  if (!fileName) {
    const xFileName = response.headers['x-filename'];
    // console.log(xFileName);
    fileName = Buffer.from(xFileName, 'base64').toString();
  } else {
    fileName = fileName ?? 'fileName';
  }

  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // or any other extension
  document.body.appendChild(link);
  link.click();
}
