// const antdVars = {
//   firstName: "John",
//   lastName: "Doe",
//   age: 25,
//   ssn: "123-456-2356",
// };

// const appVars = {
//   firstName: "John",
//   lastName: "Doe",
//   age: 25,
//   ssn: "123-456-2356",
// };

// const mergeVars = {
//   ...antdVars,
//   ...appVars,
// };

// console.log(mergeVars);

module.exports = {
  '@primary-color': '#122261',

  /* START : app vars*/
  '@secondary-color': '#ffb100',
  '@app-grey-1': '#525461',
  '@app-grey-2': '#777A89',
  '@app-grey-3': '#9CA2B5',
  '@app-grey-4': '#BFC6DC',
  '@app-grey-5': '#E2E5ED',
  '@app-grey-6': '#F1F4FD',
  '@app-bg-color': '#F9FAFF',
  '@app-white': '#FFFFFF',
  '@app-success': '#01ad6a',
  '@app-error': '#E64646',
  '@app-link': '#DC8E00',
  '@app-menu-selected': '#ffb100',
  '@app-title-text': '#363740',

  '@app-logo-url': '/images/logo-pen.svg',
  '@app-blur-bg-url': '/images/bg-blur-login.svg',
  '@app-login-bg-url': '/images/bg-login-full.svg',
  /* END : app vars*/

  '@menu-item-active-bg': '@app-grey-6',
  '@menu-item-color': '@app-grey-2',
  '@layout-header-padding': '0 20px',
  '@layout-header-height': '80px',
  '@btn-border-radius-base': '4px',
  '@background-color-light': '@app-bg-color',
  '@page-header-ghost-bg': '@app-white',
  '@page-header-heading-title': '30px',
  '@border-radius-base': '4px',
  '@breadcrumb-last-item-color': '@primary-color',
  '@table-font-size': '12px',
  '@table-selected-row-bg': 'lighten(@primary-color, 73%)',
  '@link-color': '@primary-color',
  '@form-vertical-label-padding': '0 0 0px',
  '@btn-disable-color': '@app-grey-3',
  '@btn-disable-bg': '@app-grey-5',
};
