import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  Button,
  Layout,
  Row,
  Col,
  Input,
  Form,
  notification,
  Alert,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { userApi } from '@services/api';
import * as theme from '@theme';

function ForgotPassword() {
  const query = useQuery();

  const [form] = Form.useForm();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const initData = useCallback(async () => {
    const ref = query.get('ref');
    if (ref) {
      const result = await userApi.checkUrlRefAdmin({
        refCode: ref,
      });
      if (result.status) {
        history.push({
          pathname: '/reset-password',
          // search: '?the=search',
          state: { id: result.id },
        });
      } else {
        setIsExpired(true);
      }
    }
  }, [history, query]);

  useEffect(() => {
    initData();
  }, [initData]);

  // async function initData() {
  //   const ref = query.get('ref');
  //   if (ref) {
  //     const result = await userApi.checkUrlRefAdmin({
  //       refCode: ref,
  //     });
  //     if (result.status) {
  //       history.push({
  //         pathname: '/reset-password',
  //         // search: '?the=search',
  //         state: { id: result.id },
  //       });
  //     } else {
  //       setIsExpired(true);
  //     }
  //   }
  // }

  async function onFinish(values: any) {
    // console.log('onFinish:', values);
    try {
      setIsLoading(true);

      setIsExpired(false);
      const result = await userApi.forgotPasswordAdmin({ email: values.email });
      if (result.status) {
        notification.success({
          message: 'Email has been sent',
          description:
            'Please follow the link in the email to complete your password reset request.',
        });
      } else {
        notification.error({
          message: 'This Email is not available.',
        });
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message:
          'Sorry, we cannot send email right now. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <div style={{ height: '100%', display: 'flex' }}>
        <div className="app-login-bg-left">
          <div style={{ marginTop: '5%', marginLeft: '10%' }}>
            <Row gutter={[20, 10]} align="middle">
              <Col>
                <img
                  // src="/images/logo-pen.svg"
                  src={theme['@app-logo-url']}
                  width="85%"
                  height="85%"
                  alt=""
                />
              </Col>
              <Col>
                <div
                  style={{
                    fontSize: '30px',
                    fontWeight: 600,
                    color: `${theme['@secondary-color']}`,
                  }}
                >
                  Digital Certificate
                </div>
                <div style={{ fontSize: '20px', color: 'white' }}>
                  Enterprise Digital Certificate
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ marginLeft: '15%' }}>
            <ImageLeftStyle></ImageLeftStyle>
          </div>
        </div>
        <ContainerStyle.Box>
          <ContainerStyle.Content>
            {isExpired && (
              <div style={{ marginBottom: '18px' }}>
                <Alert
                  message="Reset password link has expired, please reset your password again."
                  type="error"
                  showIcon
                />
              </div>
            )}
            <ContainerStyle.Title>
              Did you <br />
              forgot your password?
            </ContainerStyle.Title>

            <ContainerStyle.Desc>
              Enter your Email to reset your password
            </ContainerStyle.Desc>

            <Form
              name="forgotPassword"
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  {
                    type: 'email',
                    message: 'Email Address format is not valid.',
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%', borderRadius: '6px' }}
                    size="large"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                    loading={isLoading}
                  >
                    Send
                  </Button>
                )}
              </Form.Item>

              <div style={{ textAlign: 'center' }}>
                Did you remembered your password ?
                <Button type="link" onClick={() => history.push('/login')}>
                  Try again
                </Button>
              </div>
            </Form>
          </ContainerStyle.Content>
        </ContainerStyle.Box>
      </div>
    </Layout>
  );
}

const ImageLeftStyle = styled.div`
  width: 90%;
  height: 90%;
  float: right;
  position: fixed;
  top: 25%;
  /* background-image: url("/images/bg-login-full.svg"); */
  background-image: url(${theme['@app-login-bg-url']});
`;

const ContainerStyle = {
  Box: styled.div`
    height: 100%;
    background-color: white;
    width: 40%;
    display: flex;
    justify-items: center;

    /* background-image: url("/images/bg-blur-login.svg"); */
    background-image: url(${theme['@app-blur-bg-url']});
    z-index: 1;
  `,
  Content: styled.div`
    padding-left: 8%;
    position: absolute;
    top: 20%;
    left: 60%;

    width: 480px;
  `,
  Title: styled.div`
    font-size: 27px;
    font-weight: 500;
    color: ${theme['@app-title-text']};
    margin-bottom: 25px;
  `,
  Desc: styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${theme['@app-title-text']};
    margin-bottom: 40px;
  `,
};

export default ForgotPassword;
