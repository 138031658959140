import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import th from 'dayjs/locale/th';
import en from 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(timezone);

export function dayjsObj() {
  return dayjs();
}

export function dayjsObjMill(mill: number) {
  return dayjs(mill);
}

export function dayjsObjAny(value: any) {
  return dayjs(value);
}

export function dayjsObjDate(date: Date) {
  return dayjs(date);
}

export function dayjsToDate(mil: number) {
  return dayjs(mil).toDate();
}

export function dayjsAddStartOfToDate(day: number) {
  return dayjs().add(day, 'd').startOf('d').toDate();
}

export function dayjsIsBefore(mil: number) {
  return dayjs().isBefore(mil);
}

export function millisecondToDate(
  mil: number,
  locale?: string,
  format?: string
) {
  let dateTime;
  if (locale?.toLowerCase() === 'en') {
    dayjs.locale(en);
    dateTime = dayjs(mil)
      .utc()
      .format(format ?? 'DD MMM YY');
  } else {
    dayjs.locale(th);
    dayjs.extend(buddhistEra);
    dateTime = dayjs(mil)
      .utc()
      .format(format ?? 'DD MMM BB');
  }
  return dateTime;
}

export function dayjsNowToDate(locale?: string) {
  let date;
  if (locale?.toLowerCase() === 'en') {
    dayjs.locale(en);
    date = dayjs().format('DDMMYY');
  } else {
    dayjs.locale(th);
    dayjs.extend(buddhistEra);
    date = dayjs().format('DDMMBB');
  }
  return date;
}

export function dateNumberToDateFormat(dateNumber: number, locale?: string) {
  let date;
  if (locale?.toLowerCase() === 'en') {
    dayjs.locale(en);
    date = dayjs().add(dateNumber, 'd').format('DD MMM YY');
  } else {
    dayjs.locale(th);
    dayjs.extend(buddhistEra);
    date = dayjs().add(dateNumber, 'd').format('DD MMM BB');
  }
  return date;
}

export function dateNumberToDate(dateNumber: number) {
  return dayjs().add(dateNumber, 'd').toDate();
}

export function diffDateFromNow(mil: number) {
  const dateTime = dayjs(mil).format('YYYY-MM-DD');
  return dayjs().diff(dateTime, 'day');
}

export function nDaysBeforeEnd(day: number, n: number, locale?: string) {
  let date;
  if (locale?.toLowerCase() === 'en') {
    dayjs.locale(en);
    date = dayjs().add(day, 'd').subtract(n, 'd').format('DD MMM YY');
  } else {
    dayjs.locale(th);
    dayjs.extend(buddhistEra);
    date = dayjs().add(day, 'd').subtract(n, 'd').format('DD MMM BB');
  }
  return date;
}

export function millisecondToDatetime(mil: number, locale?: string) {
  let dateTime;
  if (locale?.toLowerCase() === 'en') {
    dayjs.locale(en);
    dateTime = dayjs(mil).format('DD MMM YY | HH:mm');
  } else {
    dayjs.locale(th);
    dayjs.extend(buddhistEra);
    dateTime = dayjs(mil).format('DD MMM BB | HH:mm');
  }
  return dateTime;
}

export function dateStringToDatetime(s: string, locale?: string) {
  let dateTime;
  if (locale?.toLowerCase() === 'en') {
    dayjs.locale(en);
    dateTime = dayjs(s).utc().format('DD MMM YY | HH:mm');
  } else {
    dayjs.locale(th);
    dayjs.extend(buddhistEra);
    dateTime = dayjs(s).utc().format('DD MMM BB | HH:mm');
  }
  return dateTime;
}

// Convert buddhist year
// function buddhistYear(YYYY: string) {
//   return String(Number(YYYY) + 543);
// }

const THAI_MONTHS = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายม',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
];

export function convertNumberOfMonthToThaiMonth(n: number) {
  return THAI_MONTHS[n - 1] || '';
}

export function getMin(input: number) {
  const mins = Math.floor(input / 60);
  return mins < 10 ? '0' + mins : mins;
}

export function getSec(input: number) {
  const ones = input % 60;
  return ones < 10 ? '0' + ones : ones;
}
