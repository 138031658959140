import { api, apiExternal } from '@core/api/api-client';
import { downloadFile } from '@utils/file-utils';

import * as ENDPOINT from '../endpoint';
import {
  BatchCreate,
  BatchList,
  DigitalCertificateDetail,
  DigitalCertificateInfoById,
  DigitalCertificateList,
  TemplateList,
  DigitalCertificateDownload,
} from '../models/digital-certificate-model';

export async function process(options: BatchCreate.Request) {
  const {
    file,
    batchName,
    userId,
    templateId,
    // approveId,
  } = options;

  const formData = new FormData();
  file && formData.append('file', file);
  batchName && formData.append('batchName', batchName);
  templateId && formData.append('templateId', templateId);
  // approveId && formData.append('approveId', approveId);
  userId && formData.append('userId', userId);

  const response = await api.post<BatchCreate.Response>(
    `${ENDPOINT.DIGITAL_CERTIFICATE_PROCESS}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return response.data;
}

export async function processList(options: Partial<BatchList.Request>) {
  const response = await api.post<BatchList.Response>(
    `${ENDPOINT.DIGITAL_CERTIFICATE_PROCESS_LIST}`,
    options
  );
  return response.data;
}

export async function digitalCertificateList(
  options: Partial<DigitalCertificateList.Request>
) {
  const response = await api.post<DigitalCertificateList.Response>(
    `${ENDPOINT.DIGITAL_CERTIFICATE_LIST}`,
    options
  );
  return response.data;
}

export async function digitalCertificateProcessDetail(
  options: Partial<DigitalCertificateDetail.Request>
) {
  const response = await api.post<DigitalCertificateDetail.Response>(
    `${ENDPOINT.DIGITAL_CERTIFICATE_PROCESS_DETAIL}`,
    options
  );
  return response.data;
}

export async function digitalCertificateInfoById(certId: string) {
  const response = await api
    .get<DigitalCertificateInfoById.Response>(
      `${ENDPOINT.DIGITAL_CERTIFICATE_INFO}/${certId}`
    )
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });
  return response.data;
}

export async function templateList(options?: Partial<TemplateList.Request>) {
  const validOptions = options
    ? options
    : {
        limit: {
          pageSize: 100,
          pageNumber: 1,
        },
        status: 'A',
      };
  const response = await api.post<TemplateList.Response>(
    `${ENDPOINT.DIGITAL_CERTIFICATE_TEMPLATE_LIST}`,
    validOptions
  );
  return response.data;
}

export async function downloadTemplate(url: string, filename: string) {
  const response = await apiExternal.get<Blob>(`${url}`, {
    responseType: 'blob',
  });
  downloadFile(response, filename);
}

export async function downloadAll(id: string, fileName: string) {
  const response = await api.get<Blob>(
    `${ENDPOINT.DIGITAL_CERTIFICATE_DOWNLOAD}/${id}`,
    {
      responseType: 'blob',
    }
  );
  downloadFile(response, fileName);
}

export async function downloadByIds(
  options: DigitalCertificateDownload.Request,
  fileName: string
) {
  const response = await api.post<Blob>(
    `${ENDPOINT.DIGITAL_CERTIFICATE_DOWNLOAD}`,
    options,
    {
      responseType: 'blob',
    }
  );
  downloadFile(response, fileName);
}

// export async function jobsInfoById(docId: string) {
//   const response = await axios
//     .get<DocumentInfoById.Response>(`${ENDPOINT.DIGITAL_CERTIFICATE_PROCESS}`)
//     .catch((error) => {
//       if (error.response) {
//         return error.response;
//       }
//     });
//   return response.data;
// }
