const local = {
  baseApi: 'http://52.187.125.93:4320',
};

const dev = {
  baseApi: 'http://52.187.125.93:4320',
};

const devDemo = {
  baseApi: 'http://52.187.125.93:4420',
};

const devKmitl = {
  baseApi: 'http://eds.southeastasia.cloudapp.azure.com',
};

const prdKmitl = {
  baseApi: 'https://scica.science.kmitl.ac.th',
};

const sit = {
  baseApi: 'http://52.187.125.93:4320',
};

const prod = {
  baseApi: 'http://52.187.125.93:4320',
};

let appConfig;

switch (process.env.REACT_APP_ENV) {
  case 'prod':
    appConfig = prod;
    break;
  case 'sit':
    appConfig = sit;
    break;
  case 'dev-demo':
    appConfig = devDemo;
    break;
  case 'dev-kmitl':
    appConfig = devKmitl;
    break;
  case 'prd-kmitl':
    appConfig = prdKmitl;
    break;
  case 'local':
    appConfig = local;
    break;
  case 'dev':
    appConfig = dev;
    break;
  default:
    appConfig = dev;
    break;
}

const config = {
  // default config
  other: 1,

  //
  ...appConfig,
};

// console.log(config);

export default config;
