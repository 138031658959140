import React from 'react';
import styled from 'styled-components';
import { Layout, Avatar, Menu, Dropdown } from 'antd';
import { UserOutlined, BellFilled, LogoutOutlined } from '@ant-design/icons';
// import { UserContext } from '@contexts/UserContext';
import { useHistory } from 'react-router';
import * as theme from '@theme';
import * as userLoginUtils from '@utils/user-login-utils';

const { Header } = Layout;

function HeaderMain() {
  const userInfo = userLoginUtils.getUserInfo();
  // const user = useContext(UserContext);
  // const [name, setName] = useState('');
  const history = useHistory();

  function logout() {
    localStorage.clear();
    history.push('/login');
  }

  // useEffect(() => {
  //   console.log('user', user);
  //   // setName(user.username);
  // }, [user]);

  return (
    <>
      <HeaderStyle>
        <TitlePanelStyle>
          <img
            // src="/images/logo-pen.svg"
            src={theme['@app-logo-url']}
            width="40px"
            height="40px"
            alt=""
          />
          <TitleTextStyle>Digital Certificate</TitleTextStyle>
        </TitlePanelStyle>
        <UserPanelStyle>
          <Dropdown
            overlay={
              <Menu style={{ padding: 8 }}>
                <Menu.Item style={{ marginRight: 12 }} onClick={logout}>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: theme['@primary-color'],
                    }}
                  >
                    <LogoutOutlined /> &nbsp; Log Out
                  </div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <AvartarPanelStyle>
                <Avatar size="large" icon={<UserOutlined />} />
              </AvartarPanelStyle>
              <DetailPanelStyle>
                <NameTextStyle>
                  {userInfo?.firstNameEn + ' ' + userInfo?.lastNameEn}
                </NameTextStyle>
                <RoleTextStyle>{userInfo?.email ?? ''}</RoleTextStyle>
              </DetailPanelStyle>
              <IconPanelStyle>
                {/* <BellFilled style={{ color: 'white', fontSize: 20 }} /> */}
              </IconPanelStyle>
            </a>
          </Dropdown>
          ,
        </UserPanelStyle>
      </HeaderStyle>
    </>
  );
}

const HeaderStyle = styled(Header)`
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: ${theme['@primary-color']};
`;

const TitlePanelStyle = styled.div`
  background-color: transparent;
  display: inline-block;
`;

const TitleTextStyle = styled.label`
  font-size: 18px;
  font-weight: 600;
  color: ${theme['@secondary-color']};
  padding: 0px 20px;
`;

const UserPanelStyle = styled.div`
  background-color: transparent;
  float: right;
  /* display: inline-block; */
  position: relative;
`;

const AvartarPanelStyle = styled.div`
  display: inline-block;
`;

const DetailPanelStyle = styled.div`
  display: inline-block;
  width: 150px;
`;

const IconPanelStyle = styled.div`
  display: inline-block;
  padding: 0px 20px;
`;

const NameTextStyle = styled.label`
  position: absolute;
  color: white;
  top: -10px;
  left: 60px;
  font-size: 14;
  font-weight: 600;
`;

const RoleTextStyle = styled.label`
  position: absolute;
  color: ${theme['@app-grey-4']};
  top: 10px;
  left: 60px;
  font-size: 12;
  font-weight: 400;
`;

export default HeaderMain;
