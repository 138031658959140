import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { menu } from '@constants/menu';
import * as theme from '@theme';

function SiderMain() {
  const [tab, setTab] = useState('');

  const setMenuTab = useCallback(
    (value: string) => {
      // console.log('value', value);
      if (tab !== value) {
        setTab(value ?? '');
        sessionStorage.setItem('tab', value);
      }
    },
    [tab]
  );

  // function setMenuTab(value: string) {
  //   // console.log('value', value);
  //   if (tab !== value) {
  //     setTab(value ?? '');
  //     sessionStorage.setItem('tab', value);
  //   }
  // }

  function handleSelect({ key }: any) {
    setMenuTab(key);
  }

  useEffect(() => {
    const pathName = window.location.pathname.split('/')[1];
    const menuSelected = menu.find((it) => it.route === '/' + pathName);

    sessionStorage.setItem('tab', menuSelected?.key ?? '');
    const currentTab = sessionStorage.getItem('tab');
    if (currentTab) {
      setMenuTab(currentTab);
    } else {
      sessionStorage.setItem('tab', menu[0].key);
      setTab(menu[0].key);
    }
    // console.log('menu', menu);
  }, [setMenuTab]);

  return (
    <MenuStyle
      mode="inline"
      // defaultSelectedKeys={['1']}
      // defaultOpenKeys={['sub1']}
      defaultSelectedKeys={[tab]}
      selectedKeys={[tab]}
      onSelect={handleSelect}
      theme={'light'}
    >
      {menu &&
        menu.length > 0 &&
        menu.map((e: any) => (
          <Menu.Item key={e.key} icon={e.icon}>
            {e.name}
            <Link to={e.route} />
          </Menu.Item>
        ))}
    </MenuStyle>
  );
}

const MenuStyle = styled(Menu)`
  height: 100%;
  border-right: 0;

  .ant-menu-item-selected {
    ::after {
      left: 0 !important;
      right: inherit;
      border-right: 3px solid ${theme['@app-menu-selected']} !important;
    }
  }
`;

export default SiderMain;
