import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
} from 'react-router-dom';
import './App.less';

import DocumentInfo from './pages/document-info';
import DigitalCertificateInfo from './pages/digital-certificate-info';
import Authen from './Authentication';
import Login from './pages/login';
import DigitalCertifications from './pages/digital-certificate/index';
import Users from './pages/users';
import Certificates from './pages/certificates/index';
import Detail from './pages/digital-certificate/[id]/index';
import CreateUserPage from './pages/users/employee/create/index';
import EditUserPage from './pages/users/employee/edit/[id]';
import CreateCustomerPage from './pages/users/customer/create/index';
import EditCustomePage from './pages/users/customer/edit/[id]';
import UserAdmin from './pages/user-admin/index';
import CreateAdminPage from './pages/user-admin/create/index';
import EditAdminPage from './pages/user-admin/edit/[id]';
import ForgotPasswordPage from './pages/forgot-password';
import ResetPasswordPage from './pages/reset-password';
import HomePage from './pages/home';

export default function App() {
  // useEffect(() => {
  //   console.log('router');
  // }, []);

  return (
    <Router>
      <div>
        <Switch>
          {/* ----- Public Path ----- */}
          <Route path="/" exact children={<Login />} />
          <Route path="/login" children={<Login />} />
          <Route path="/forgot-password" children={<ForgotPasswordPage />} />
          <Route
            path="/reset-password"
            exact
            children={<ResetPasswordPage />}
          />
          <Route path="/doc-info/:id" children={<DocumentInfo />} />
          <Route path="/cert-info/:id" children={<DigitalCertificateInfo />} />
          <Route path="/home" children={<HomePage />} />

          {/* ----- Private Path ----- */}
          {/* <PrivateRoute path="/" exact children={<Login />} /> */}
          <PrivateRoute path="/users" exact children={<Users />} />
          <PrivateRoute
            path="/users/employee/create"
            children={<CreateUserPage />}
          />
          <PrivateRoute
            path="/users/employee/edit/:id"
            children={<EditUserPage />}
          />
          <PrivateRoute
            path="/users/customer/create"
            children={<CreateCustomerPage />}
          />
          <PrivateRoute
            path="/users/customer/edit/:id"
            children={<EditCustomePage />}
          />
          <PrivateRoute path="/user-admin" exact children={<UserAdmin />} />
          <PrivateRoute
            path="/user-admin/create"
            children={<CreateAdminPage />}
          />
          <PrivateRoute
            path="/user-admin/edit/:id"
            children={<EditAdminPage />}
          />
          <PrivateRoute
            path="/digital-certificate"
            exact
            children={<DigitalCertifications />}
          />
          <PrivateRoute path="/digital-certificate/:id" children={<Detail />} />
          <PrivateRoute path="/certificates" children={<Certificates />} />
        </Switch>
      </div>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }: RouteProps) {
  return <Route {...rest} render={(props) => Authen(props, children)} />;
}
