import * as ENDPOINT from '../endpoint';
import { api } from '@core/api/api-client';
import { CompaniesList } from '../models/companies-model';

export async function list(options?: Partial<CompaniesList.Request>) {
  const validOptions = options
    ? options
    : {
        limit: {
          pageSize: 100,
          pageNumber: 1,
        },
        status: 'A',
      };
  const response = await api.post<CompaniesList.Response>(
    `${ENDPOINT.COMPANIES_LIST}`,
    validOptions
  );
  return response.data;
}

// export async function getInvestorList(
//     projectId: string,
//     options?: Partial<InvestorList.Request>
//   ) {
//     const response = await axios.post<InvestorList.Response>(
//       ENDPOINT.INVESTOR_LIST,
//       {
//         projectId,
//         limit: {
//           pageNumber: 1,
//           pageSize: 100
//         },
//         ...(options || {})
//       }
//     )
//     return response.data
//   }
