import { User } from '@services/models/user-model';
import React from 'react';

export const UserContext = React.createContext({});

export type UserContextType = {
  children: React.ReactNode;
  data?: User;
};

export function UserProvider({ children, data }: any) {
  return (
    <UserContext.Provider value={(data ?? {}) as User}>
      {children}
    </UserContext.Provider>
  );
}
