import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, PageHeader, Row, Col, Input, Form, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import LayoutMain from '@components/layout/Layout';
import { userApi } from '@services/api';
import * as theme from '@theme';

const routes = [
  {
    path: '/users',
    breadcrumbName: 'Users',
  },
  {
    path: '#',
    breadcrumbName: 'Create Customer',
  },
];

function CreateCustomerPage() {
  const history = useHistory();
  const [userForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = async (values: any) => {
    // console.log('Finish:', values);
    try {
      setIsLoading(true);
      const payload = { ...values };
      await userApi.createCustomer(payload);
      notification.success({
        message: 'Your customer was sucessfully created.',
      });
      history.push('/users');
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message:
          'Sorry, we cannot create your customer right now. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LayoutMain>
      <PageHeaderStyle
        title="Create Customer"
        onBack={() => window.history.back()}
        breadcrumb={{
          routes,
          itemRender: (route: any) => (
            <Link to={route.path}>{route.breadcrumbName}</Link>
          ),
        }}
      />
      <ContentStyle>
        <Form {...layout} form={userForm} onFinish={onFinish}>
          <ContentHeaderStyle>Personal Information</ContentHeaderStyle>

          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="First Name (EN)"
                name="firstNameEn"
                rules={[
                  {
                    required: true,
                    message: 'First Name (EN) is required',
                  },
                  {
                    pattern: /^[^ก-๙-ูเ-ํ]+$/,
                    message: 'First Name (EN) must be in english',
                  },
                  {
                    max: 255,
                    message:
                      'First Name (EN) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Last Name (EN)"
                name="lastNameEn"
                rules={[
                  {
                    required: true,
                    message: 'Last Name (EN) is required',
                  },
                  {
                    pattern: /^[^ก-๙-ูเ-ํ]+$/,
                    message: 'Last Name (EN) must be in english',
                  },
                  {
                    max: 255,
                    message:
                      'Last Name (EN) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="First Name (TH)"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'First Name (TH) is required',
                  },
                  {
                    pattern: /^[ก-ูเ-ํ ]+$/,
                    message: 'First Name (TH) must be in thai',
                  },
                  {
                    max: 255,
                    message:
                      'First Name (TH) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Last Name (TH)"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Last Name (TH) is required',
                  },
                  {
                    pattern: /^[ก-ูเ-ํ ]+$/,
                    message: 'Last Name (TH) must be in thai',
                  },
                  {
                    max: 255,
                    message:
                      'Last Name (TH) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                  () => ({
                    async validator(rule, value) {
                      const list = await userApi.list({
                        email: value,
                      });
                      if (list.data && list.data[0]?.email === value) {
                        return Promise.reject('This email is already taken.');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Telephone Number"
                name="telephone"
                rules={[
                  {
                    required: true,
                    message: 'Telephone Number is required',
                  },
                  {
                    pattern: /^[0-9]{10,10}$/,
                    message: 'Telephone Number must be 10 digits',
                  },
                ]}
              >
                <Input placeholder="0XX-XXX-XXXX" style={{ width: '50%' }} />
              </Form.Item>
            </Col>
          </Row>

          <ContentHeaderStyle>Password</ContentHeaderStyle>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="Password"
                name="loginPassword"
                rules={[
                  {
                    required: true,
                    message: 'Password is required',
                  },
                  {
                    min: 8,
                    pattern: new RegExp(
                      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
                    ),
                    message: '',
                  },
                  {
                    max: 45,
                    message: '',
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Confirm Password"
                name="cfmPwd"
                rules={[
                  {
                    required: true,
                    message: 'Confirm Password is required',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('loginPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Confirm Password does not match');
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm password" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <PasswordRequirementStyle.Box>
                <PasswordRequirementStyle.Header>
                  Password requirements
                </PasswordRequirementStyle.Header>
                <ul>
                  <li>At least 8 charecters</li>
                  <li>At least one number</li>
                  <li>At least one upper case letter</li>
                  <li>At least one lower case letter</li>
                </ul>
              </PasswordRequirementStyle.Box>
            </Col>
          </Row>
          <Row
            gutter={[32, 0]}
            style={{ justifyContent: 'flex-end', marginTop: '24px' }}
          >
            <Col>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                style={{ padding: '0px 32px' }}
              >
                Create New User
              </Button>
            </Col>
          </Row>
        </Form>
      </ContentStyle>
    </LayoutMain>
  );
}
const PasswordRequirementStyle = {
  Box: styled.div`
    padding: 14px 18px 12px 18px;
    background: #f9faff;

    border: 1px dashed #bfc6dc;
    box-sizing: border-box;
    border-radius: 6px;

    > ul {
      color: ${theme['@app-success']};
      line-height: 22px;
    }
  `,
  Header: styled.div`
    line-height: 36px;
    color: #777a89;
  `,
};

const PageHeaderStyle = styled(PageHeader)`
  padding: '24px';

  .ant-page-header-heading
    > .ant-page-header-heading-left
    > .ant-page-header-heading-title {
    line-height: 40px;
  }
`;
const ContentHeaderStyle = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 60px;

  margin-bottom: 20px;
  border-bottom: 1px solid #e2e5ed;
  color: #525461;
`;

const ContentStyle = styled.div`
  margin: 24px;
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(227, 228, 235, 0.25);
`;

export default CreateCustomerPage;
