import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { DocumentInfoById, Signer } from '@services/models/document-model';
import { millisecondToDate } from '@utils/date-utils';

type Props = {
  data?: DocumentInfoById.Response;
};

function DocumentSignInfo(props: Props) {
  const { data } = props;

  const [docInfo, setDocInfo] = useState<DocumentInfoById.Response>();
  const [companyList, setCompanyList] = useState<Signer[]>();
  const [customerList, setCustomerList] = useState<Signer[]>();
  const [employeeList, setEmployeeList] = useState<Signer[]>();

  useEffect(() => {
    if (data) {
      setDocInfo(data);
      filterCompany(data);
      filterCustomer(data);
      filterEmployee(data);
    }
  }, [data]);

  function getName(item: any, length: number) {
    if (item) {
      let name = '';
      if (item.isCompany) {
        name = item.firstNameEn.replace(/\s+/g, '');
        return name.slice(0, length).toUpperCase();
      } else {
        name = (item?.firstNameEn[0] ?? '').concat(item?.lastNameEn[0] ?? '');
        return name.toUpperCase();
      }
    }
    return '';
  }

  function filterCompany(docData: DocumentInfoById.Response) {
    if (docData && docData.signerList && docData.signerList.length > 0) {
      const list: any = docData.signerList
        .filter((e) => e.isCompany)
        .filter((v, i, a) => a.indexOf(v) === i);
      // for (const index in list) {
      for (const index of Object.keys(list)) {
        const signer = docData.signerList.filter(
          (e) => e.companyId === list[index].userId
        );
        signer.sort((x, y) => Number(y.isCompany) - Number(x.isCompany));
        list[index].userList = signer;
      }
      setCompanyList(list);
      // console.log('list', list);
    }
  }

  function filterCustomer(docData: DocumentInfoById.Response) {
    if (docData && docData.signerList && docData.signerList.length > 0) {
      const list: any = docData.signerList
        .filter((e) => e.type == 2 && !e.isCompany)
        .filter((v, i, a) => a.indexOf(v) === i);
      for (const index of Object.keys(list)) {
        const signer = docData.signerList.filter(
          (e) => e.userId === list[index].userId
        );
        signer.sort((x, y) => Number(y.isCompany) - Number(x.isCompany));
        list[index].userList = signer;
      }
      setCustomerList(list);
      // console.log('list', list);
    }
  }

  function filterEmployee(docData: DocumentInfoById.Response) {
    if (docData && docData.signerList && docData.signerList.length > 0) {
      const list: any = docData.signerList
        .filter((e) => e.type == 1 && !e.isCompany)
        .filter((v, i, a) => a.indexOf(v) === i);
      for (const index of Object.keys(list)) {
        const signer = docData.signerList.filter(
          (e) => e.userId === list[index].userId
        );
        signer.sort((x, y) => Number(y.isCompany) - Number(x.isCompany));
        list[index].userList = signer;
      }
      setEmployeeList(list);
      // console.log('list', list);
    }
  }

  return (
    <>
      <Row>
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div
            style={{
              padding: '60px 10px 10px 10px',
              backgroundColor: '#BFC6DC',
            }}
            className="topicText"
          >
            Summary
          </div>
          <div className="height10" />
        </Col>
        <Col xs={24} className="pt10 pb10 pl20 pr20">
          <div className="secondText">Certificate Of Completion</div>
          <div className="height10" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">{'Document ID'}</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {data?.document?.id ?? '-'}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">{'Status'}</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak floatRight">
                <div className="center labelSign bold500">{'Signed'}</div>
              </div>
            </Col>
          </Row>
          <Row className="height5" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">{'Document Name'}</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {docInfo?.document?.fileNameOriginal ?? '-'}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">Document Categories</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {docInfo?.document?.categoryName ?? '-'}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">Due Date</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {docInfo?.document?.dueDate
                  ? millisecondToDate(
                    docInfo.document.dueDate,
                    'en',
                    'DD MMM YYYY hh:mm A'
                  )
                  : '-'}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">Create Date</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {docInfo?.document?.createDate
                  ? millisecondToDate(
                    docInfo?.document?.createDate,
                    'en',
                    'DD MMM YYYY hh:mm A'
                  )
                  : '-'}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">Signer</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {docInfo?.signerList?.length ?? 0}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div className="height10" />
        </Col>
        <Col xs={24} className="pt10 pb10 pl20 pr20">
          <div className="secondText">Sender Information</div>
          <div className="height10" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">Sender</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {docInfo?.creatorInfo?.firstNameEn ?? ''}{' '}
                {docInfo?.creatorInfo?.lastNameEn ?? ''}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
          <Row>
            <Col xs={10}>
              <div className="font12 wordBreak left">Email</div>
            </Col>
            <Col xs={14}>
              <div className="font12 wordBreak right">
                {docInfo?.creatorInfo?.email ?? '-'}
              </div>
            </Col>
          </Row>
          <Row className="height5" />
        </Col>
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div className="height10" />
        </Col>
        <Col xs={24} className="pt10 pb10 pl20 pr20">
          <div className="secondText">Signer Information</div>
          <div className="height5" />
        </Col>
        <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
          <div className="height5" />
        </Col>
        {companyList && companyList?.length > 0
          ? companyList?.map((ee: any, eeindex: number) => (
            <Col xs={24} key={ee.userId + eeindex}>
              <Col
                xs={24}
                className="pt5 pb5 pl10 pr10 font12"
                style={{ backgroundColor: '#E2E5ED', color: '#9CA2B5' }}
              >
                <Row>
                  <Col xs={24} className="pr10">
                    {/* {getName(ee, 3) + ' ' + '( ' + (ee?.firstNameEn ?? '') + ' )'} */}
                    {ee?.firstNameEn ?? ''}
                  </Col>
                  <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
                    <div className="height5" />
                  </Col>
                </Row>
              </Col>
              {ee.userList &&
                ee.userList.length > 0 &&
                ee.userList.map((e: any, index: number) => (
                  <>
                    <Col
                      xs={24}
                      className="pt10 pb10 pl20 pr20"
                      key={e.userId + index}
                    >
                      <Row>
                        <Col xs={24} className="pr10">
                          <div className="box40 bgGreen circle displayInlineBlock">
                            <div className="center labelGreen font14 boldText">
                              {getName(e, 2)}
                            </div>
                          </div>
                          <div className="responsive boldText pl10 pr10 displayInlineBlock">
                            {e?.firstNameEn ?? ''} {e?.lastNameEn ?? ''}
                          </div>

                          <div className="box40 displayInlineBlock floatRight">
                            <div className="center responsive labelSign">
                              <div className="floatRight">{'Signed'}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="height5" />
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">Email</div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.email ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      {!e?.isCompany && <Row className="height5" />}
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">
                              Viewed
                              </div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.viewedDate
                                ? millisecondToDate(
                                  e?.viewedDate,
                                  'en',
                                  'DD MMM YYYY hh:mm A'
                                )
                                : '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row className="height5" />
                      <Row>
                        <Col xs={10}>
                          <div className="font12 wordBreak left">Signed</div>
                        </Col>
                        <Col xs={14}>
                          <div className="font12 wordBreak right">
                            {e?.signedDate
                              ? millisecondToDate(
                                e?.signedDate,
                                'en',
                                'DD MMM YYYY hh:mm A'
                              )
                              : '-'}
                          </div>
                        </Col>
                      </Row>
                      {!e?.isCompany && <Row className="height5" />}
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">
                              Using IP
                              </div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.signedIp ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      {!e?.isCompany && <Row className="height5" />}
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">
                              Using Device
                              </div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.signedDevice ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row className="height5" />
                    </Col>
                    <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
                      <div className="height5" />
                    </Col>
                  </>
                ))}
            </Col>
          ))
          : (
            <div />
          )}

        {employeeList && employeeList?.length > 0
          ? employeeList?.map((ee: any, eeindex: number) => (
            <Col xs={24} key={ee.userId + eeindex}>
              <Col
                // xs={24}
                // className="pt5 pb5 pl10 pr10 font12"
                // style={{ backgroundColor: '#E2E5ED', color: '#9CA2B5' }}
              >
              </Col>
              {ee.userList &&
                ee.userList.length > 0 &&
                ee.userList.map((e: any, index: number) => (
                  <>
                    <Col
                      xs={24}
                      className="pt10 pb10 pl20 pr20"
                      key={e.userId + index}
                    >
                      <Row>
                        <Col xs={24} className="pr10">
                          <div className="box40 bgGreen circle displayInlineBlock">
                            <div className="center labelGreen font14 boldText">
                              {getName(e, 2)}
                            </div>
                          </div>
                          <div className="responsive boldText pl10 pr10 displayInlineBlock">
                            {e?.firstNameEn ?? ''} {e?.lastNameEn ?? ''}
                          </div>

                          <div className="box40 displayInlineBlock floatRight">
                            <div className="center responsive labelSign">
                              <div className="floatRight">{'Signed'}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="height5" />
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">Email</div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.email ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      {!e?.isCompany && <Row className="height5" />}
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">
                              Viewed
                              </div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.viewedDate
                                ? millisecondToDate(
                                  e?.viewedDate,
                                  'en',
                                  'DD MMM YYYY hh:mm A'
                                )
                                : '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row className="height5" />
                      <Row>
                        <Col xs={10}>
                          <div className="font12 wordBreak left">Signed</div>
                        </Col>
                        <Col xs={14}>
                          <div className="font12 wordBreak right">
                            {e?.signedDate
                              ? millisecondToDate(
                                e?.signedDate,
                                'en',
                                'DD MMM YYYY hh:mm A'
                              )
                              : '-'}
                          </div>
                        </Col>
                      </Row>
                      {!e?.isCompany && <Row className="height5" />}
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">
                              Using IP
                              </div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.signedIp ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      {!e?.isCompany && <Row className="height5" />}
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">
                              Using Device
                              </div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.signedDevice ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row className="height5" />
                    </Col>
                    <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
                      <div className="height5" />
                    </Col>
                  </>
                ))}
            </Col>
          ))
          : (
            <div />
          )}
        {customerList && customerList?.length > 0
          ?
          customerList?.map((ee: any, eeindex: number) => (
            <Col xs={24} key={ee.userId + eeindex}>


              {eeindex == 0 ?
                <Col
                  xs={24}
                  className="pt5 pb5 pl10 pr10 font12"
                  style={{ backgroundColor: '#E2E5ED', color: '#9CA2B5' }}
                >
                  <Row>
                    <Col xs={24} className="pr10">
                      {'Customer'}
                    </Col>
                    <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
                      <div className="height5" />
                    </Col>
                  </Row>
                </Col>
                : <div />
              }

              {ee.userList &&
                ee.userList.length > 0 &&
                ee.userList.map((e: any, index: number) => (
                  <>
                    <Col
                      xs={24}
                      className="pt10 pb10 pl20 pr20"
                      key={e.userId + index}
                    >
                      <Row>
                        <Col xs={24} className="pr10">
                          <div className="box40 bgGreen circle displayInlineBlock">
                            <div className="center labelGreen font14 boldText">
                              {getName(e, 2)}
                            </div>
                          </div>
                          <div className="responsive boldText pl10 pr10 displayInlineBlock">
                            {e?.firstNameEn ?? ''} {e?.lastNameEn ?? ''}
                          </div>

                          <div className="box40 displayInlineBlock floatRight">
                            <div className="center responsive labelSign">
                              <div className="floatRight">{'Signed'}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="height5" />
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">Email</div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.email ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      {!e?.isCompany && <Row className="height5" />}
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">
                              Viewed
                              </div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.viewedDate
                                ? millisecondToDate(
                                  e?.viewedDate,
                                  'en',
                                  'DD MMM YYYY hh:mm A'
                                )
                                : '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row className="height5" />
                      <Row>
                        <Col xs={10}>
                          <div className="font12 wordBreak left">Signed</div>
                        </Col>
                        <Col xs={14}>
                          <div className="font12 wordBreak right">
                            {e?.signedDate
                              ? millisecondToDate(
                                e?.signedDate,
                                'en',
                                'DD MMM YYYY hh:mm A'
                              )
                              : '-'}
                          </div>
                        </Col>
                      </Row>
                      {!e?.isCompany && <Row className="height5" />}
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">
                              Using IP
                              </div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.signedIp ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      {!e?.isCompany && <Row className="height5" />}
                      {!e?.isCompany && (
                        <Row>
                          <Col xs={10}>
                            <div className="font12 wordBreak left">
                              Using Device
                              </div>
                          </Col>
                          <Col xs={14}>
                            <div className="font12 wordBreak right">
                              {e?.signedDevice ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row className="height5" />
                    </Col>
                    <Col xs={24} style={{ backgroundColor: '#E2E5ED' }}>
                      <div className="height5" />
                    </Col>
                  </>
                ))}
            </Col>
          ))
          : (
            <div />
          )}
      </Row>
    </>
  );
}

export default DocumentSignInfo;
