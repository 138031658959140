export const CERTIFICATE_STATUS = {
  WAITING_FOR_APPROVAL: 10,
  REJECTED: 20,
  APPROVED: 30,
  INPROGRESS: 40,
  COMPLETED: 50,
  FAILED: 60,
};

export const DEFAULT_PAGE = {
  SIZE: 10,
  NUMBER: 1,
};

// status
export const STATUS = {
  ACTIVE: 'A',
  INACTIVE: 'I',
};

export const DIRECTION = {
  false: 'DESC',
  ascend: 'ASC',
  descend: 'DESC',
};

export const ORIENTATION = {
  PORTRAIT: 'Portrait',
  LANDSCAPE: 'Landscape'
}
