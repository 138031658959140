import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  Button,
  PageHeader,
  Row,
  Col,
  Input,
  Form,
  Select,
  notification,
} from 'antd';
import { useHistory, Link, useParams } from 'react-router-dom';
import LayoutMain from '@components/layout/Layout';
import { companiesApi, userApi } from '@services/api';
import { CompaniesList } from '@services/models/companies-model';
import { Entity } from '@services/models/user-admin-model';
import * as userLoginUtils from '@utils/user-login-utils';

const routes = [
  {
    path: '/user-admin',
    breadcrumbName: 'User Admin',
  },
  {
    path: '#',
    breadcrumbName: 'Edit User Admin',
  },
];

function EditAdminPage() {
  const { id }: any = useParams();
  const [userForm] = Form.useForm();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState<
    CompaniesList.Entity[]
  >([]);
  const [user, setUser] = useState<Entity>();

  const userInfo = userLoginUtils.getUserInfo();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const initData = useCallback(async () => {
    setIsLoading(true);
    await Promise.all([
      await companiesApi.list(),
      await userApi.getAdminById(id),
    ])
      .then((result) => {
        setCompaniesOptions(result[0].data);
        setUser(result[1]);
      })
      .catch(() => {
        setCompaniesOptions([]);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    initData();
  }, [initData]);

  const mapfieldValuetoForm = useCallback(async () => {
    if (user) {
      userForm.setFields(
        Object.entries(user).map(([key, value]) => {
          // moment value need to assign here before send back to form
          return {
            name: key,
            value,
          };
        })
      );
    }
  }, [user, userForm]);

  useEffect(() => {
    mapfieldValuetoForm();
  }, [mapfieldValuetoForm]);

  // async function initData() {
  //   setIsLoading(true);
  //   await Promise.all([
  //     await companiesApi.list(),
  //     await userApi.getAdminById(id),
  //   ])
  //     .then((result) => {
  //       setCompaniesOptions(result[0].data);
  //       setUser(result[1]);
  //     })
  //     .catch(() => {
  //       setCompaniesOptions([]);
  //       setIsLoading(false);
  //     })
  //     .finally(() => setIsLoading(false));
  // }

  const onFinish = async (values: any) => {
    // console.log('Finish:', values);
    try {
      setIsLoading(true);
      const company = companiesOptions.find(
        (it: CompaniesList.Entity) => it.id === values.companyId
      );
      const payload = {
        companyName: company?.name || '',
        ...values,
        id,
        updateBy: userInfo?.id,
      };
      await userApi.updateAdmin(payload);
      notification.success({ message: 'Your user was sucessfully updated.' });
      history.push('/user-admin');
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message:
          'Sorry, we cannot update your user right now. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LayoutMain>
      <PageHeaderStyle
        title={user ? `${user.firstNameEn} ${user.lastNameEn}` : '-'}
        onBack={() => window.history.back()}
        breadcrumb={{
          routes,
          itemRender: (route: any) => (
            <Link to={route.path}>{route.breadcrumbName}</Link>
          ),
        }}
      />
      <ContentStyle>
        <Form {...layout} form={userForm} onFinish={onFinish}>
          <ContentHeaderStyle>Personal Information</ContentHeaderStyle>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="Employee ID"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Employee ID is required',
                  },
                  () => ({
                    async validator(rule, value) {
                      const list = await userApi.adminList({
                        code: value,
                      });
                      if (list.data && list.data[0]?.code === value) {
                        return Promise.reject(
                          'This employee ID is already taken.'
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={12}>
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Status is required",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={STATUS.ACTIVE}>Active</Radio>
                  <Radio value={STATUS.INACTIVE}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="First Name (EN)"
                name="firstNameEn"
                rules={[
                  {
                    required: true,
                    message: 'First Name (EN) is required',
                  },
                  {
                    pattern: /^[^ก-๙-ูเ-ํ]+$/,
                    message: 'First Name (EN) must be in english',
                  },
                  {
                    max: 255,
                    message:
                      'First Name (EN) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Last Name (EN)"
                name="lastNameEn"
                rules={[
                  {
                    required: true,
                    message: 'Last Name (EN) is required',
                  },
                  {
                    pattern: /^[^ก-๙-ูเ-ํ]+$/,
                    message: 'Last Name (EN) must be in english',
                  },
                  {
                    max: 255,
                    message:
                      'Last Name (EN) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="First Name (TH)"
                name="firstNameTh"
                rules={[
                  {
                    required: true,
                    message: 'First Name (TH) is required',
                  },
                  {
                    pattern: /^[ก-ูเ-ํ ]+$/,
                    message: 'First Name (TH) must be in thai',
                  },
                  {
                    max: 255,
                    message:
                      'First Name (TH) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Last Name (TH)"
                name="lastNameTh"
                rules={[
                  {
                    required: true,
                    message: 'Last Name (TH) is required',
                  },
                  {
                    pattern: /^[ก-ูเ-ํ ]+$/,
                    message: 'Last Name (TH) must be in thai',
                  },
                  {
                    max: 255,
                    message:
                      'Last Name (TH) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <ContentHeaderStyle>Company Information</ContentHeaderStyle>
          <Row gutter={[32, 0]}>
            <Col xs={6}>
              <Form.Item
                label="Company"
                name="companyId"
                rules={[
                  {
                    required: true,
                    message: 'Company is required',
                  },
                ]}
              >
                <Select>
                  {companiesOptions.map(
                    (data: CompaniesList.Entity, idx: number) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={[32, 0]}
            style={{ justifyContent: 'flex-end', marginTop: '24px' }}
          >
            <Col>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                style={{ padding: '0px 50px' }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </ContentStyle>
    </LayoutMain>
  );
}

const PageHeaderStyle = styled(PageHeader)`
  padding: '24px';

  .ant-page-header-heading
    > .ant-page-header-heading-left
    > .ant-page-header-heading-title {
    line-height: 40px;
  }
`;
const ContentHeaderStyle = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 60px;

  margin-bottom: 20px;
  border-bottom: 1px solid #e2e5ed;
  color: #525461;
`;

const ContentStyle = styled.div`
  margin: 24px;
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(227, 228, 235, 0.25);
`;

export default EditAdminPage;
