import { api } from '@core/api/api-client';

import * as ENDPOINT from '../endpoint';
import {
  UserList,
  UserCreate,
  UserGetById,
  UserEdit,
  UserLogin,
  CustomerCreate,
  CustomerEdit,
} from '../models/user-model';
import {
  UserAdminList,
  UserAdminCreate,
  UserAdminEdit,
  UserAdminGetById,
  UserAdminForgot,
  UserAdminUrlRef,
  UserAdminReset,
} from '../models/user-admin-model';

export async function list(options?: Partial<UserList.Request>) {
  const payload: Partial<UserList.Request> = {
    limit: { pageNumber: 1, pageSize: 10 },
    ...options,
  };
  const response = await api.post<UserList.Response>(
    `${ENDPOINT.USERS_LIST}`,
    payload
  );
  return response.data;
}

export async function create(options?: Partial<UserCreate.Request>) {
  const payload = {
    registerPlatform: 'WEB',
    loginName: options?.email || '',
    ...options,
  };
  // console.log("payload", payload);
  const response = await api.post<UserCreate.Response>(
    `${ENDPOINT.USERS_BASE_URL}`,
    payload
  );
  return response.data;
}

export async function createCustomer(
  options?: Partial<CustomerCreate.Request>
) {
  const payload = {
    registerPlatform: 'WEB',
    loginName: options?.email || '',
    ...options,
  };
  // console.log("payload", payload);
  const response = await api.post<CustomerCreate.Response>(
    `${ENDPOINT.USERS_CUSTOMER_BASE_URL}`,
    payload
  );
  return response.data;
}

export async function login(username: string, password: string) {
  const payload = {
    loginName: username,
    loginPassword: password,
  };
  const response = await api.post<UserLogin.Response>(
    `${ENDPOINT.USERS_LOGIN}`,
    payload
  );
  return response.data;
}

/**
 * Inquiry user by id
 */
export async function getById(id: string) {
  const response = await api.get<UserGetById.Response>(
    `${ENDPOINT.USERS_BASE_URL}/${id}`
  );
  return response.data.data;
}

export async function update(options?: Partial<UserEdit.Request>) {
  const payload = {
    ...options,
  };
  // console.log("payload", payload);
  const response = await api.put<UserEdit.Response>(
    `${ENDPOINT.USERS_BASE_URL}`,
    payload
  );
  return response.data;
}

export async function updateCustomer(options?: Partial<CustomerEdit.Request>) {
  const payload = {
    ...options,
  };
  // console.log("payload", payload);
  const response = await api.put<CustomerEdit.Response>(
    `${ENDPOINT.USERS_BASE_URL}`,
    payload
  );
  return response.data;
}

export async function adminList(options?: Partial<UserAdminList.Request>) {
  const payload: Partial<UserAdminList.Request> = {
    limit: { pageNumber: 1, pageSize: 10 },
    ...options,
  };
  const response = await api.post<UserAdminList.Response>(
    `${ENDPOINT.USERS_ADMIN_LIST}`,
    payload
  );
  return response.data;
}

export async function createAdmin(options?: Partial<UserAdminCreate.Request>) {
  const payload = {
    loginName: options?.email || '',
    ...options,
  };
  // console.log("payload", payload);
  const response = await api.post<UserAdminCreate.Response>(
    `${ENDPOINT.USERS_ADMIN_BASE_URL}`,
    payload
  );
  return response.data;
}

export async function updateAdmin(options?: Partial<UserAdminEdit.Request>) {
  const payload = {
    ...options,
  };
  // console.log("payload", payload);
  const response = await api.put<UserAdminEdit.Response>(
    `${ENDPOINT.USERS_ADMIN_BASE_URL}`,
    payload
  );
  return response.data;
}

/**
 * Inquiry admin by id
 */
export async function getAdminById(id: string) {
  const response = await api.get<UserAdminGetById.Response>(
    `${ENDPOINT.USERS_ADMIN_BASE_URL}/${id}`
  );
  return response.data.data;
}

export async function forgotPasswordAdmin(
  options: Partial<UserAdminForgot.Request>
) {
  const response = await api.post<UserAdminForgot.Response>(
    `${ENDPOINT.USERS_ADMIN_FORGOT_PASSWORD}`,
    options
  );
  return response.data;
}

export async function checkUrlRefAdmin(
  options: Partial<UserAdminUrlRef.Request>
) {
  const response = await api.post<UserAdminUrlRef.Response>(
    `${ENDPOINT.USERS_ADMIN_CHECK_URL_REF}`,
    options
  );
  return response.data;
}

export async function resetPasswordAdmin(
  options: Partial<UserAdminReset.Request>
) {
  const response = await api.post<UserAdminReset.Response>(
    `${ENDPOINT.USERS_ADMIN_RESET_PASSWORD}`,
    options
  );
  return response.data;
}
