import { CERTIFICATE_STATUS } from '@constants/constants';

export function mapStatusCode(status: number) {
  switch (status) {
    case CERTIFICATE_STATUS.COMPLETED:
      return {
        text: 'Completed',
        color: '#01AD6A',
      };
    case CERTIFICATE_STATUS.FAILED:
      return {
        text: 'Failed',
        color: '#E64646',
      };
    case CERTIFICATE_STATUS.REJECTED:
      return {
        text: 'Rejected',
        color: '#E64646',
      };
    case CERTIFICATE_STATUS.WAITING_FOR_APPROVAL:
      return {
        text: 'Waiting for approval',
        color: '#FF8900',
      };
    case CERTIFICATE_STATUS.INPROGRESS:
      return {
        text: 'In Progress',
        color: '#FF8900',
      };
    case CERTIFICATE_STATUS.APPROVED:
      return {
        text: 'New',
        color: '#FF8900',
      };
    default:
      return { text: 'All', color: '#FFFFFF' };
  }
}

export function calculateTableRange(
  pageSize: number,
  pageNumber: number,
  total: number,
  dataLength: number
) {
  const start = pageSize * (pageNumber - 1) + 1;
  const end = pageSize * (pageNumber - 1) + dataLength;
  return { start, end, total };
}
