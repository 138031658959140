import React from 'react';
import PropTypes from 'prop-types';

import {
  RightOutlined,
  LeftOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';

export const CustomPrevButton = (props: any) => {
  const { page, handlePrevClick } = props;
  // if (page === 1) return <div style={{ display: 'inline-block' }} />

  return (
    <div
      className="responsive"
      style={{
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: 24,
        marginTop: 0,
        pointerEvents: page === 1 ? 'none' : 'auto',
        color: page === 1 ? '#808080' : 'white',
      }}
      onClick={handlePrevClick}
    >
      {/* <Previous page> */}
      <LeftOutlined />
    </div>
  );
};
CustomPrevButton.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  handlePrevClick: PropTypes.func.isRequired,
};

export const CustomNextButton = (props: any) => {
  const { page, pages, handleNextClick } = props;
  // if (page === pages) return <div style={{ display: 'inline-block' }} />

  return (
    // <h3
    //     style={{
    //         cursor: 'pointer',
    //         display: 'inline-block',
    //         marginLeft: 24,
    //         marginTop: 0,
    //     }}
    //     onClick={handleNextClick}>
    //     Next page
    // </h3>
    <div
      className="responsive"
      style={{
        cursor: 'pointer',
        display: 'inline-block',
        marginLeft: 24,
        marginTop: 0,
        pointerEvents: page === pages ? 'none' : 'auto',
        color: page === pages ? '#808080' : '#fff',
      }}
      onClick={handleNextClick}
    >
      {/* <Previous page> */}
      <RightOutlined />
    </div>
  );
};
CustomNextButton.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  handleNextClick: PropTypes.func.isRequired,
};

export const CustomPages = (props: any) => {
  const { page, pages } = props;
  return (
    <div
      className="responsive"
      style={{ display: 'inline-block', marginTop: 0, color: '#fff' }}
    >
      Page {page} / {pages}
    </div>
  );
};
CustomPages.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
};

export const CustomZoomOutButton = (props: any) => {
  const { handleZoomOut } = props;
  // if (page === 1) return <div />

  return (
    <div
      className="responsive"
      style={{
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: 24,
        marginTop: 0,
        color: '#fff',
      }}
      onClick={handleZoomOut}
    >
      {/* <Previous page> */}
      <ZoomOutOutlined />
    </div>
  );
};
CustomZoomOutButton.propTypes = {
  handleZoomOut: PropTypes.func.isRequired,
};

export const CustomZoomInButton = (props: any) => {
  const { handleZoomIn } = props;
  // if (page === 1) return <div />

  return (
    <div
      className="responsive"
      style={{
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: 24,
        marginTop: 0,
        color: '#fff',
      }}
      onClick={handleZoomIn}
    >
      {/* <Previous page> */}
      <ZoomInOutlined />
    </div>
  );
};
CustomZoomInButton.propTypes = {
  handleZoomIn: PropTypes.func.isRequired,
};

const CustomNavigation = (props: any) => {
  const { page, pages } = props;

  const {
    handlePrevClick,
    handleNextClick,
    handleZoomIn,
    handleZoomOut,
  } = props;

  return (
    <div className="customWrapper">
      <CustomZoomOutButton handleZoomOut={handleZoomOut} />
      <CustomZoomInButton handleZoomIn={handleZoomIn} />
      <div style={{ display: 'inline-block', width: '20px' }}></div>
      <CustomPrevButton
        page={page}
        pages={pages}
        handlePrevClick={handlePrevClick}
      />
      <CustomPages page={page} pages={pages} />
      <CustomNextButton
        page={page}
        pages={pages}
        handleNextClick={handleNextClick}
      />
    </div>
  );
};
CustomNavigation.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  handlePrevClick: PropTypes.func.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  handleZoomIn: PropTypes.func.isRequired,
  handleZoomOut: PropTypes.func.isRequired,
};

export default CustomNavigation;
