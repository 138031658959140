import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Row,
  Col,
  Input,
  Form,
  PageHeader,
  Table,
  DatePicker,
} from 'antd';
import { Link } from 'react-router-dom';
import LayoutMain from '@components/layout/Layout';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  DigitalCertificateList,
  CertificateEntity,
} from '@services/models/digital-certificate-model';
import { digitalCertificateApi } from '@services/api';
import { calculateTableRange, mapStatusCode } from '@utils/data-utils';
import { dateStringToDatetime, dayjsNowToDate } from '@utils/date-utils';
import {
  DEFAULT_PAGE,
  CERTIFICATE_STATUS,
  STATUS,
  DIRECTION,
} from '@constants/constants';
import { Pagination, TableRange } from '@services/models/common';
import { SortOrder } from 'antd/lib/table/interface';
import * as theme from '@theme';

const PageSize = 10;
const DEFAULT_SORTBY = 'createDate';

type TableData = {
  key: string;
  code: string;
  studentName: string;
  digitalCertificateProcessId: string;
  processCode: string;
  studentId: string;
  certificateName: string;
  signedByName: string;
  createdDate: Date;
  status: number;
  docUrl: string;
};

function Certificates() {
  const [searchForm] = Form.useForm();

  // const [state, setState] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDownloadAll, setIsLoadingDownloadAll] = useState(false);
  const [rowSelectedKeys, setRowSelectedKeys] = useState<string[]>([]);

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tablePage, setTablePage] = useState<Pagination>();
  const [tableRange, setTableRange] = useState<TableRange>();
  const [
    tableRequest,
    setTableRequest,
  ] = useState<DigitalCertificateList.Request>({
      limit: { pageSize: PageSize, pageNumber: 1 },
      state: CERTIFICATE_STATUS.COMPLETED,
      status: STATUS.ACTIVE,
      sortBy: DEFAULT_SORTBY,
      sortDirectionBy: 'DESC',
    });

  const getList = useCallback(async () => {
    try {
      setIsLoading(true);
      const result: DigitalCertificateList.Response =
        await digitalCertificateApi.digitalCertificateList(tableRequest);
      // console.log('result : ', result);

      if (result && result.page) {
        setTablePage(result.page);
        if (result.certificate && result.certificate.length > 0) {
          const dataTable = result.certificate.map(
            (it: CertificateEntity, idx: number) => ({
              key: it.data.id,
              code: it.data.code,
              studentName: it.data.studentName,
              digitalCertificateProcessId: it.data.digitalCertificateProcessId,
              processCode: it.data.processCode,
              studentId: it.data.studentCode,
              certificateName: it.data.certificateName,
              signedByName: it.data.signedByName,
              createdDate: it.data.createDate,
              status: it.data.state,
              docUrl: it.info.docPublicUrl,
            })
          );
          setTableData(dataTable);
          setTableRange(
            calculateTableRange(
              result.page.pageSize || 0,
              result.page.pageNumber || 0,
              result.page.total,
              result.certificate.length
            )
          );
        } else {
          setTableData([]);
          setTableRange(undefined);
        }
      } else {
        setTableData([]);
        setTableRange(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [tableRequest]);

  useEffect(() => {
    getList();
  }, [getList]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  // async function getList() {
  //   try {
  //     setIsLoading(true);
  //     const result: DigitalCertificateList.Response = await digitalCertificateApi.digitalCertificateList(
  //       tableRequest
  //     );
  //     // console.log('result : ', result);

  //     if (result && result.page) {
  //       if (result.certificate && result.certificate.length > 0) {
  //         const dataTable = result.certificate.map(
  //           (it: CertificateEntity, idx: number) => ({
  //             key: it.data.id,
  //             certificateId: it.data.id,
  //             studentName: it.data.studentName,
  //             digitalCertificateProcessId: it.data.digitalCertificateProcessId,
  //             studentId: it.data.studentCode,
  //             certificateName: it.data.certificateName,
  //             signedByName: it.data.signedByName,
  //             createdDate: it.data.createDate,
  //             status: it.data.state,
  //             docUrl: it.info.docPublicUrl,
  //           })
  //         );
  //         setTableData(dataTable);
  //         setTablePage({ ...result.page });
  //         setTableRange(
  //           calculateTableRange(
  //             result.page.pageSize || 0,
  //             result.page.pageNumber || 0,
  //             result.page.total,
  //             result.certificate.length
  //           )
  //         );
  //       } else {
  //         setTableData([]);
  //         setTableRange(undefined);
  //       }
  //     } else {
  //       setTableData([]);
  //       setTableRange(undefined);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  const onFinish = (values: any) => {
    // console.log('Finish:', values);
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: 1,
      },
      createDate: values.createDate?.startOf('day').format(),
      studentName: values.studentName,
      certificateName: values.certificateName,
    });
  };

  function handleOnRowSelectionChange(rowKeys: React.Key[]) {
    // console.log(rowKeys);
    setRowSelectedKeys(rowKeys.map((t: React.Key) => t?.toString()));
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TableData[]) => {
      handleOnRowSelectionChange(selectedRowKeys);
    },
    getCheckboxProps: (record: TableData) => ({
      // disabled: false,
    }),
  };

  const onClear = () => {
    searchForm.resetFields();
    // searchForm.setFieldsValue({
    //   status: "All",
    // });
    setTableRequest({
      limit: { pageSize: PageSize, pageNumber: 1 },
      state: CERTIFICATE_STATUS.COMPLETED,
      status: STATUS.ACTIVE,
      sortBy: DEFAULT_SORTBY,
      sortDirectionBy: 'DESC',
    });
  };

  const columns = [
    {
      title: 'Certificate ID',
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      render: (text: string, record: TableData) => <>{text || record.key}</>,
    },
    {
      title: 'Batch ID',
      dataIndex: 'processCode',
      key: 'processCode',
      sorter: true,
      render: (text: string, record: TableData, index: number) => (
        <LinkStyle>
          <Link
            to={'/digital-certificate/' + record.digitalCertificateProcessId}
          >
            {text || record.digitalCertificateProcessId}
          </Link>
        </LinkStyle>
      ),
    },
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
      sorter: true,
    },
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentCode',
      sorter: true,
    },
    {
      title: 'Certificate Name',
      dataIndex: 'certificateName',
      key: 'certificateName',
      sorter: true,
    },
    {
      title: 'Signed By',
      dataIndex: 'signedByName',
      key: 'signedByName',
      sorter: true,
    },
    {
      title: 'Create Date',
      dataIndex: 'createdDate',
      key: 'createDate',
      sorter: true,
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string) => dateStringToDatetime(text, 'en'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'state',
      sorter: true,
      render: (text: string) => textStatus(text),
    },
    {
      title: 'Action',
      dataIndex: 'docUrl',
      key: 'docUrl',
      render: (text: string) => (
        <LinkDonwloadStyle href={text}>Download</LinkDonwloadStyle>
      ),
    },
  ];

  function textStatus(state: string) {
    const statusDetail = mapStatusCode(Number(state));
    return (
      <div style={{ color: statusDetail?.color }}>{statusDetail?.text}</div>
    );
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // console.log("sorter", sorter);
    // console.log("pagination", pagination);

    const order = sorter.order ?? 'descend';
    const direction = DIRECTION[order as keyof typeof DIRECTION];
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: pagination.current,
      },
      sortBy: sorter.order ? sorter.columnKey : DEFAULT_SORTBY,
      sortDirectionBy: direction as 'ASC' | 'DESC',
    });
  };

  const downloadSelectedItems = async () => {
    setIsLoadingDownloadAll(true);
    try {
      await digitalCertificateApi.downloadByIds(
        { certificateIdList: rowSelectedKeys },
        'Certificates_' + dayjsNowToDate('en')
      );
    } catch (error) {
      setIsLoadingDownloadAll(false);
    } finally {
      setIsLoadingDownloadAll(false);
    }
  };

  // function createSelectItems() {
  //   let items = [];
  //   for (let i = 0; i <= 60; i += 10) {
  //     if (i !== 10 && i !== 20) {
  //       items.push(
  //         <Select.Option key={i} value={i}>
  //           {mapStatusCode(i).text}
  //         </Select.Option>
  //       );
  //     }
  //     //here I will be creating my options dynamically based on
  //     //what props are currently passed to the parent component
  //   }
  //   return items;
  // }

  return (
    <LayoutMain>
      <PageHeader className="site-page-header" title="Certificate List" />
      <ContentStyle>
        <Form {...layout} form={searchForm} onFinish={onFinish}>
          <Row gutter={[32, 0]}>
            <Col xs={5}>
              <Form.Item label="Student name" name="studentName">
                <Input
                  // onPressEnter={onFinish}
                  placeholder="Student name"
                />
              </Form.Item>
            </Col>

            <Col xs={5}>
              <Form.Item label="Certificate name" name="certificateName">
                <Input
                  // onPressEnter={onFinish}
                  placeholder="Certificate name"
                />
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Form.Item label="Create date" name="createDate">
                <DatePicker
                  style={{ width: '100%' }}
                  // onChange={(value) =>
                  //   setIssueDate(value?.startOf("day").format())
                  // }
                />
              </Form.Item>
            </Col>
            {/* <Col xs={5}>
              <Form.Item label="Status" name="status">
                <Select onChange={handleChange}>{createSelectItems()}</Select>
              </Form.Item>
            </Col> */}
            <Col>
              <SearchPanelStyle>
                <Button htmlType="submit" type="primary">
                  Search
                </Button>
                &nbsp;
                <Button type="link" onClick={onClear}>
                  Clear
                </Button>
              </SearchPanelStyle>
            </Col>
          </Row>
        </Form>

        <div className="page-count-panel">
          <div className="page-count-label">
            {tableRange && (
              <>
                {`${tableRange?.start}-${tableRange?.end} of `}
                <span>{tableRange?.total || 0} </span> results
              </>
            )}
          </div>
          <div>
            <Button
              icon={<DownloadOutlined />}
              onClick={downloadSelectedItems}
              loading={isLoadingDownloadAll}
            >
              Download{' '}
              {rowSelectedKeys.length > 0 && (
                <>{` (${rowSelectedKeys.length} items)`}</>
              )}
            </Button>
          </div>
        </div>

        <Table
          showSorterTooltip={false}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tableData ? tableData : []}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: DEFAULT_PAGE.SIZE,
            defaultCurrent: DEFAULT_PAGE.NUMBER,
            current: tablePage?.pageNumber,
            pageSize: PageSize,
            total: tablePage?.total || 0,
          }}
          loading={isLoading}
        />
      </ContentStyle>
    </LayoutMain>
  );
}
const SearchPanelStyle = styled.div`
  display: flex;
  padding-top: 30px;
  height: 100%;
`;

const ContentStyle = styled.div`
  margin: 24px;
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  box-shadow: 0px 2px 8px #e3e4eb, 25%;
`;
const LinkDonwloadStyle = styled.a`
  color: ${theme['@app-link']};
  text-decoration: underline;
`;

const LinkStyle = styled.div`
  text-decoration: underline;
`;

export default Certificates;
