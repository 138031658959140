import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button, PageHeader, Row, Col, Input, Form, Table, Select } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import LayoutMain from '@components/layout/Layout';
import { UserAddOutlined } from '@ant-design/icons';
import { DEFAULT_PAGE, STATUS, DIRECTION } from '@constants/constants';
import { companiesApi, userApi } from '@services/api';
import { CompaniesList } from '@services/models/companies-model';
import { UserAdminList, Entity } from '@services/models/user-admin-model';
import { Pagination, TableRange } from '@services/models/common';
import { calculateTableRange } from '@utils/data-utils';
import { dateStringToDatetime } from '@utils/date-utils';
import { SortOrder } from 'antd/lib/table/interface';

type TableData = {
  key: string;
  employeeId: string;
  firstNameEn: string;
  lastNameEn: string;
  firstNameTh: string;
  lastNameTh: string;
  email: string;
  companyName: string;
  createdDate: Date;
  updatedDate: Date;
};

const PageSize = 10;
const DEFAULT_SORTBY = 'updateDate';

function UserAdmin() {
  const history = useHistory();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const [searchForm] = Form.useForm();
  const [companiesOptions, setCompaniesOptions] = useState<
    CompaniesList.Entity[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tablePage, setTablePage] = useState<Pagination>();
  const [tableRange, setTableRange] = useState<TableRange>();
  const [tableRequest, setTableRequest] = useState<UserAdminList.Request>({
    limit: { pageSize: PageSize, pageNumber: DEFAULT_PAGE.NUMBER },
    status: STATUS.ACTIVE,
    sortBy: DEFAULT_SORTBY,
    sortDirectionBy: 'DESC',
  });

  const initData = useCallback(async () => {
    setIsLoading(true);
    searchForm.setFieldsValue({
      companyId: '',
    });
    await Promise.all([await companiesApi.list()])
      .then((result) => setCompaniesOptions(result[0].data))
      .catch(() => {
        setCompaniesOptions([]);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  }, [searchForm]);

  const getList = useCallback(async () => {
    try {
      setIsLoading(true);
      const result: UserAdminList.Response = await userApi.adminList(
        tableRequest
      );
      // console.log('result : ', result);
      if (result && result.page) {
        setTablePage(result.page);
        if (result.data && result.data.length > 0) {
          const dataTable = result.data.map((it: Entity, idx: number) => ({
            key: it.id,
            employeeId: it.code,
            firstNameEn: it.firstNameEn,
            lastNameEn: it.lastNameEn,
            firstNameTh: it.firstNameTh,
            lastNameTh: it.lastNameTh,
            email: it.email,
            companyName: it.companyName,
            createdDate: it.createDate,
            updatedDate: it.updateDate,
          }));
          setTableData(dataTable);
          setTableRange(
            calculateTableRange(
              result.page.pageSize || 0,
              result.page.pageNumber || 0,
              result.page.total,
              result.data.length
            )
          );
        } else {
          setTableData([]);
          setTableRange(undefined);
        }
      }
    } catch (error) {
      setTableRange(undefined);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [tableRequest]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    getList();
  }, [getList]);

  // async function initData() {
  //   setIsLoading(true);
  //   searchForm.setFieldsValue({
  //     companyId: '',
  //   });
  //   await Promise.all([await companiesApi.list()])
  //     .then((result) => setCompaniesOptions(result[0].data))
  //     .catch(() => {
  //       setCompaniesOptions([]);
  //       setIsLoading(false);
  //     })
  //     .finally(() => setIsLoading(false));
  // }

  // async function getList() {
  //   try {
  //     setIsLoading(true);
  //     const result: UserAdminList.Response = await userApi.adminList(
  //       tableRequest
  //     );
  //     // console.log('result : ', result);
  //     if (result && result.page) {
  //       setTablePage({ ...result.page });
  //       setTableRange(
  //         calculateTableRange(
  //           result.page.pageSize || 0,
  //           result.page.pageNumber || 0,
  //           result.page.total,
  //           result.data.length
  //         )
  //       );

  //       if (result.data && result.data.length > 0) {
  //         const dataTable = result.data.map((it: Entity, idx: number) => ({
  //           key: it.id,
  //           employeeId: it.code,
  //           firstNameEn: it.firstNameEn,
  //           lastNameEn: it.lastNameEn,
  //           firstNameTh: it.firstNameTh,
  //           lastNameTh: it.lastNameTh,
  //           email: it.email,
  //           companyName: it.companyName,
  //           createdDate: it.createDate,
  //           updatedDate: it.updateDate,
  //         }));
  //         setTableData(dataTable);
  //       } else {
  //         setTableData([]);
  //         setTableRange(undefined);
  //       }
  //     }
  //   } catch (error) {
  //     setTableRange(undefined);
  //     setIsLoading(false);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  const onFinish = (values: any) => {
    // console.log('Finish:', values);
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: 1,
      },
      companyId: values.companyId,
      text: values.name,
    });
  };

  const onClear = () => {
    searchForm.resetFields();
    searchForm.setFieldsValue({
      companyId: '',
    });
    setTableRequest({
      limit: { pageSize: PageSize, pageNumber: DEFAULT_PAGE.NUMBER },
      status: STATUS.ACTIVE,
      sortBy: DEFAULT_SORTBY,
      sortDirectionBy: 'DESC',
    });
  };

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employeeId',
      key: 'code',
      sorter: true,
      // render: (text: string, record: TableData) => (
      //   <LinkStyle>
      //     <Link to={'/user-admin/edit/' + record.key}>{text}</Link>
      //   </LinkStyle>
      // ),
    },
    {
      title: 'First Name (EN)',
      dataIndex: 'firstNameEn',
      key: 'firstNameEn',
      sorter: true,
      render: (text: string, record: TableData) => (
        <LinkStyle>
          <Link to={'/user-admin/edit/' + record.key}>{text}</Link>
        </LinkStyle>
      ),
    },
    {
      title: 'Last Name (EN)',
      dataIndex: 'lastNameEn',
      key: 'lastNameEn',
      sorter: true,
    },
    {
      title: 'First Name (TH)',
      dataIndex: 'firstNameTh',
      key: 'firstNameTh',
      sorter: true,
    },
    {
      title: 'Last Name (TH)',
      dataIndex: 'lastNameTh',
      key: 'lastNameTh',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createDate',
      sorter: true,
      render: (text: any) => dateStringToDatetime(text, 'en'),
    },
    {
      title: 'Last modified Date',
      dataIndex: 'updatedDate',
      key: 'updateDate',
      sorter: true,
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: any) => dateStringToDatetime(text, 'en'),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // console.log("sorter", sorter);
    // console.log("pagination", pagination);

    const order = sorter.order ?? 'descend';
    const direction = DIRECTION[order as keyof typeof DIRECTION];
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: pagination.current,
      },
      sortBy: sorter.order ? sorter.columnKey : DEFAULT_SORTBY,
      sortDirectionBy: direction as 'ASC' | 'DESC',
    });
  };

  return (
    <LayoutMain>
      <PageHeader
        className="site-page-header"
        title="User Admin"
        extra={[
          <Button
            key={0}
            type="default"
            icon={<UserAddOutlined />}
            onClick={() => {
              history.push('/user-admin/create');
            }}
          >
            Create New Admin
          </Button>,
        ]}
      />

      <ContentStyle>
        <Form {...layout} form={searchForm} onFinish={onFinish}>
          <Row gutter={[32, 32]}>
            <Col xs={5}>
              <Form.Item label="Search" name="name">
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Form.Item label="Company" name="companyId">
                <Select>
                  <Select.Option key="999" value="">
                    All
                  </Select.Option>
                  {companiesOptions.map(
                    (data: CompaniesList.Entity, idx: number) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <SearchPanelStyle>
                <Button htmlType="submit" type="primary">
                  Search
                </Button>
                &nbsp;
                <Button type="link" onClick={onClear}>
                  Clear
                </Button>
              </SearchPanelStyle>
            </Col>
          </Row>
        </Form>
        <div className="page-count-panel">
          <div className="page-count-label">
            {tableRange && (
              <>
                {`${tableRange?.start}-${tableRange?.end} of `}
                <span>{tableRange?.total || 0} </span> results
              </>
            )}
          </div>
          <div></div>
        </div>
        <Table
          showSorterTooltip={false}
          dataSource={tableData ? tableData : []}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            showSizeChanger: false,
            current: tablePage?.pageNumber,
            pageSize: PageSize,
            total: tablePage?.total || 0,
          }}
          loading={isLoading}
        />
      </ContentStyle>
    </LayoutMain>
  );
}

const LinkStyle = styled.div`
  text-decoration: underline;
`;

const ContentStyle = styled.div`
  margin: 24px;
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(227, 228, 235, 0.25);
`;

const SearchPanelStyle = styled.div`
  display: flex;
  padding-top: 30px;
  height: 100%;
`;

export default UserAdmin;
