import { UserLoginInfo } from '@services/models/common';

const USER_LOGIN_KEY = 'userinfo';

export function logout() {
  return localStorage.removeItem(USER_LOGIN_KEY);
}

export function setUserLoginInfo(info: UserLoginInfo) {
  return localStorage.setItem(USER_LOGIN_KEY, JSON.stringify(info));
}

export function getUserInfo() {
  const val = localStorage.getItem(USER_LOGIN_KEY);
  if (val) {
    return JSON.parse(val) as UserLoginInfo;
  }
  return null;
}

export function isLogedIn() {
  return getUserInfo() != null;
}

export function getUserToken() {
  return getUserInfo()?.token;
}
