import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  Button,
  PageHeader,
  Row,
  Col,
  Input,
  Form,
  Select,
  Radio,
  notification,
  Modal,
} from 'antd';
import { useHistory, Link, useParams } from 'react-router-dom';
import LayoutMain from '@components/layout/Layout';
import { companiesApi, userApi } from '@services/api';
import { CompaniesList } from '@services/models/companies-model';
import { Entity } from '@services/models/user-model';
import { STATUS } from '@constants/constants';
import { CheckOutlined } from '@ant-design/icons';
import * as userLoginUtils from '@utils/user-login-utils';

const routes = [
  {
    path: '/users',
    breadcrumbName: 'Users',
  },
  {
    path: '#',
    breadcrumbName: 'Edit Employee',
  },
];

function EditCustomerPage() {
  const { id }: any = useParams();
  const [userForm] = Form.useForm();
  const history = useHistory();

  const [isVisible, setIsVisible] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState<
    CompaniesList.Entity[]
  >([]);
  const [user, setUser] = useState<Entity>();

  const userInfo = userLoginUtils.getUserInfo();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const initData = useCallback(async () => {
    setIsLoading(true);
    await Promise.all([await companiesApi.list(), await userApi.getById(id)])
      .then((result) => {
        setCompaniesOptions(result[0].data);
        setUser(result[1]);
        setIsReset(result[1].securityCodeStatus === 0);
      })
      .catch(() => {
        setCompaniesOptions([]);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    initData();
  }, [initData]);

  const mapfieldValuetoForm = useCallback(async () => {
    if (user) {
      userForm.setFields(
        Object.entries(user).map(([key, value]) => {
          // moment value need to assign here before send back to form
          return {
            name: key,
            value,
          };
        })
      );
    }
  }, [user, userForm]);

  useEffect(() => {
    mapfieldValuetoForm();
  }, [mapfieldValuetoForm]);

  const onFinish = async (values: any) => {
    // console.log('Finish:', values);
    try {
      setIsLoading(true);
      const company = companiesOptions.find(
        (it: CompaniesList.Entity) => it.id === values.companyId
      );
      const payload = {
        companyName: company?.name || '',
        ...values,
        id,
        updateBy: userInfo?.id,
      };
      await userApi.update(payload);
      notification.success({
        message: 'Your enterprise user was sucessfully edited.',
      });
      history.push('/users');
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message:
          'Sorry, we cannot edit your enterprise user right now. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderResetSecurityCode = () => {
    return (
      <Modal
        width={400}
        visible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
        footer={null}
      >
        <ModalStyle.Container>
          <ModalStyle.Title>Reset security code ?</ModalStyle.Title>
          <ModalStyle.Footer>
            <Button onClick={() => setIsVisible(false)}>Cancel</Button>
            &nbsp;&nbsp;
            <Button type="primary" onClick={() => resetSecurityCode()}>
              Reset
            </Button>
          </ModalStyle.Footer>
        </ModalStyle.Container>
      </Modal>
    );
  };

  const resetSecurityCode = () => {
    setIsVisible(false);
    Modal.success({
      title: 'Security code has been reset.',
      content: 'You can reset it again in 5 minutes.',
    });
    setIsReset(true);
  };

  return (
    <LayoutMain>
      <PageHeaderStyle
        title={user ? `${user.firstNameEn} ${user.lastNameEn}` : '-'}
        onBack={() => window.history.back()}
        breadcrumb={{
          routes,
          itemRender: (route: any) => (
            <Link to={route.path}>{route.breadcrumbName}</Link>
          ),
        }}
      />
      {renderResetSecurityCode()}
      <ContentStyle>
        <Form {...layout} form={userForm} onFinish={onFinish}>
          <ContentHeaderStyle>Personal Information</ContentHeaderStyle>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="Employee ID"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Employee ID is required',
                  },
                  () => ({
                    async validator(rule, value) {
                      const list = await userApi.list({
                        code: value,
                      });
                      if (list.data && list.data[0]?.code === value) {
                        return Promise.reject(
                          'This employee ID is already taken.'
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Status is required',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={STATUS.ACTIVE}>Active</Radio>
                  <Radio value={STATUS.INACTIVE}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="First Name (EN)"
                name="firstNameEn"
                rules={[
                  {
                    required: true,
                    message: 'First Name (EN) is required',
                  },
                  {
                    pattern: /^[^ก-๙-ูเ-ํ]+$/,
                    message: 'First Name (EN) must be in english',
                  },
                  {
                    max: 255,
                    message:
                      'First Name (EN) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Last Name (EN)"
                name="lastNameEn"
                rules={[
                  {
                    required: true,
                    message: 'Last Name (EN) is required',
                  },
                  {
                    pattern: /^[^ก-๙-ูเ-ํ]+$/,
                    message: 'Last Name (EN) must be in english',
                  },
                  {
                    max: 255,
                    message:
                      'Last Name (EN) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="First Name (TH)"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'First Name (TH) is required',
                  },
                  {
                    pattern: /^[ก-ูเ-ํ ]+$/,
                    message: 'First Name (TH) must be in thai',
                  },
                  {
                    max: 255,
                    message:
                      'First Name (TH) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Last Name (TH)"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Last Name (TH) is required',
                  },
                  {
                    pattern: /^[ก-ูเ-ํ ]+$/,
                    message: 'Last Name (TH) must be in thai',
                  },
                  {
                    max: 255,
                    message:
                      'Last Name (TH) must not longer than 255 characters',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Telephone Number"
                name="telephone"
                rules={[
                  {
                    required: true,
                    message: 'Telephone Number is required',
                  },
                  {
                    pattern: /^[0-9]{10,10}$/,
                    message: 'Telephone Number must be 10 digits',
                  },
                ]}
              >
                <Input placeholder="0XX-XXX-XXXX" style={{ width: '50%' }} />
              </Form.Item>
            </Col>
          </Row>

          <ContentHeaderStyle>Company Information</ContentHeaderStyle>
          <Row gutter={[32, 0]}>
            <Col xs={6}>
              <Form.Item
                label="Company"
                name="companyId"
                rules={[
                  {
                    required: true,
                    message: 'Company is required',
                  },
                ]}
              >
                <Select>
                  {companiesOptions.map(
                    (data: CompaniesList.Entity, idx: number) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                label="Organization"
                name="organizationName"
                rules={[
                  {
                    required: true,
                    message: 'Organization is required',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                label="Department"
                name="departmentName"
                rules={[
                  {
                    required: true,
                    message: 'Department is required',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                label="Position"
                name="positionName"
                rules={[
                  {
                    required: true,
                    message: 'Position is required',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={12}>
              <Form.Item label="Delegate Person" name="deledatePerson">
                <Select disabled>
                  <Select.Option value="01">Jack daniels</Select.Option>
                  <Select.Option value="02">Lucy Deena</Select.Option>
                  <Select.Option value="03">Priya Ponnappa</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Role"
                name="userType"
                rules={[
                  {
                    required: true,
                    message: 'Role is required',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>Individual</Radio>
                  <Radio value={2}>Enterprise</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={[32, 0]}
            style={{ justifyContent: 'flex-end', marginTop: '24px' }}
          >
            <Col>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
                style={{ padding: '0px 50px' }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </ContentStyle>
      <ContentStyle>
        <ContentHeaderStyle>Security</ContentHeaderStyle>
        <Row gutter={[32, 0]} align="middle">
          <Col xs={8}>
            <ItemStyle.Title>Security code</ItemStyle.Title>
            <ItemStyle.Desc>Used for document signing</ItemStyle.Desc>
          </Col>
          <Col xs={4}>
            <Button
              style={{ padding: '0px 16px' }}
              onClick={() => setIsVisible(true)}
              icon={isReset ? <CheckOutlined /> : <></>}
              disabled={isReset}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </ContentStyle>
    </LayoutMain>
  );
}

const ModalStyle = {
  Container: styled.div`
    margin: 12px 12px;
  `,

  Title: styled.div`
    margin-bottom: 24px;
    font-size: 16px;
  `,
  Footer: styled.div`
    text-align: end;
  `,
};

const ItemStyle = {
  Title: styled.div`
    font-size: 16px;
    font-weight: 500;
  `,

  Desc: styled.div`
    color: #525461;
  `,
};

const PageHeaderStyle = styled(PageHeader)`
  padding: '24px';

  .ant-page-header-heading
    > .ant-page-header-heading-left
    > .ant-page-header-heading-title {
    line-height: 40px;
  }
`;
const ContentHeaderStyle = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 60px;

  margin-bottom: 20px;
  border-bottom: 1px solid #e2e5ed;
  color: #525461;
`;

const ContentStyle = styled.div`
  margin: 24px;
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(227, 228, 235, 0.25);
`;

export default EditCustomerPage;
