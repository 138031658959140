import React, { useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import {
  Button,
  PageHeader,
  Row,
  Col,
  Input,
  Form,
  Table,
  Select,
  Modal,
} from 'antd';
import { useHistory, Link } from 'react-router-dom';
import LayoutMain from '@components/layout/Layout';
import {
  UserAddOutlined,
  DownloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { DEFAULT_PAGE, STATUS, DIRECTION } from '@constants/constants';
import { companiesApi, userApi } from '@services/api';
import { Pagination, TableRange } from '@services/models/common';
import { UserList, Entity as UserEntity } from '@services/models/user-model';
import { calculateTableRange } from '@utils/data-utils';
import { dateStringToDatetime } from '@utils/date-utils';
import { CompaniesList } from '@services/models/companies-model';
import { SortOrder } from 'antd/lib/table/interface';
import * as theme from '@theme';

type TableData = {
  key: string;
  employeeId: string;
  firstNameEn: string;
  lastNameEn: string;
  email: string;
  companyName: string;
  // organizationName: string;
  // departmentName: string;
  // positionName: string;
  role: number;
  type: number;
  status: string;
  createdDate: Date;
  updatedDate: Date;
};

const PageSize = 10;
const DEFAULT_SORTBY = 'updateDate';

function Users() {
  const history = useHistory();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const [searchForm] = Form.useForm();
  const [rowSelectedKeys, setRowSelectedKeys] = useState<string[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState<
    CompaniesList.Entity[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tablePage, setTablePage] = useState<Pagination>();
  const [tableRange, setTableRange] = useState<TableRange>();
  const [tableRequest, setTableRequest] = useState<UserList.Request>({
    limit: { pageSize: PageSize, pageNumber: 1 },
    sortBy: DEFAULT_SORTBY,
    sortDirectionBy: 'DESC',
  });

  const [isVisible, setIsVisible] = useState(false);

  const initData = useCallback(async () => {
    try {
      setIsLoading(true);

      searchForm.setFieldsValue({
        companyId: '',
        type: 0,
        status: '',
        userTypes: 0,
      });
      const companies: CompaniesList.Response = await companiesApi.list();
      setCompaniesOptions(companies.data);
    } catch (error) {
      setCompaniesOptions([]);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [searchForm]);

  const getList = useCallback(async () => {
    try {
      setIsLoading(true);
      const result: UserList.Response = await userApi.list(tableRequest);
      // console.log('result : ', result);
      if (result && result.page) {
        setTablePage(result.page);

        if (result.data && result.data.length > 0) {
          const dataTable = result.data.map(
            (data: UserEntity, idx: number) => ({
              key: data.id,
              employeeId: data.code,
              firstNameEn: data.firstNameEn,
              lastNameEn: data.lastNameEn,
              email: data.email,
              companyName: data.companyName,
              // organizationName: data.organizationName,
              // departmentName: data.departmentName,
              // positionName: data.positionName,
              role: data.userType,
              type: data.type,
              status: data.status,
              createdDate: data.createDate,
              updatedDate: data.updateDate,
            })
          );
          setTableData(dataTable);
          setTableRange(
            calculateTableRange(
              result.page.pageSize || 0,
              result.page.pageNumber || 0,
              result.page.total,
              result.data.length
            )
          );
        } else {
          setTableData([]);
          setTableRange(undefined);
        }
      }
    } catch (error) {
      setTableRange(undefined);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [tableRequest]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    getList();
  }, [getList]);

  // async function initData() {
  //   try {
  //     setIsLoading(true);

  //     searchForm.setFieldsValue({
  //       companyId: '',
  //       type: '',
  //       status: '',
  //       // userTypes: "",
  //     });
  //     const companies: CompaniesList.Response = await companiesApi.list();
  //     setCompaniesOptions(companies.data);
  //   } catch (error) {
  //     setCompaniesOptions([]);
  //     setIsLoading(false);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // console.log("sorter", sorter);
    // console.log("pagination", pagination);

    const order = sorter.order ?? 'descend';
    const direction = DIRECTION[order as keyof typeof DIRECTION];
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: pagination.current,
      },
      sortBy: sorter.order ? sorter.columnKey : DEFAULT_SORTBY,
      sortDirectionBy: direction as 'ASC' | 'DESC',
    });
  };

  // async function getList() {
  //   try {
  //     setIsLoading(true);
  //     const result: UserList.Response = await userApi.list(tableRequest);
  //     // console.log('result : ', result);
  //     if (result && result.page) {
  //       setTablePage({ ...result.page });
  //       setTableRange(
  //         calculateTableRange(
  //           result.page.pageSize || 0,
  //           result.page.pageNumber || 0,
  //           result.page.total,
  //           result.data.length
  //         )
  //       );

  //       if (result.data && result.data.length > 0) {
  //         const dataTable = result.data.map(
  //           (data: UserEntity, idx: number) => ({
  //             key: data.id,
  //             employeeId: data.code,
  //             firstNameEn: data.firstNameEn,
  //             lastNameEn: data.lastNameEn,
  //             email: data.email,
  //             companyName: data.companyName,
  //             // organizationName: data.organizationName,
  //             // departmentName: data.departmentName,
  //             // positionName: data.positionName,
  //             role: data.userType,
  //             type: 'type',
  //             status: data.status,
  //             createdDate: data.createDate,
  //             updatedDate: data.updateDate,
  //           })
  //         );
  //         setTableData(dataTable);
  //       } else {
  //         setTableData([]);
  //         setTableRange(undefined);
  //       }
  //     }
  //   } catch (error) {
  //     setTableRange(undefined);
  //     setIsLoading(false);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  const onFinish = (values: any) => {
    // console.log('Finish:', values);
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: 1,
      },
      companyId: values.companyId,
      userTypes: Number(values.userTypes ?? 0),
      text: values.name,
      type: values.type,
      status: values.status,
    });
  };

  const onClear = () => {
    searchForm.resetFields();
    searchForm.setFieldsValue({
      companyId: '',
      type: 0,
      status: '',
      userTypes: 0,
    });
    setTableRequest({
      limit: { pageSize: PageSize, pageNumber: 1 },
      sortBy: DEFAULT_SORTBY,
      sortDirectionBy: 'DESC',
    });
  };

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employeeId',
      key: 'code',
      sorter: true,
      render: (text: any) => <>{text || '-'}</>,
    },
    {
      title: 'First Name (EN)',
      dataIndex: 'firstNameEn',
      key: 'firstNameEn',
      sorter: true,
      render: (text: string, record: TableData, index: number) => (
        <LinkStyle>
          {record.type && record.type === 1 ? (
            <Link to={'/users/employee/edit/' + record.key}>{text}</Link>
          ) : (
            <Link to={'/users/customer/edit/' + record.key}>{text}</Link>
          )}
        </LinkStyle>
      ),
    },
    {
      title: 'Last Name (EN)',
      dataIndex: 'lastNameEn',
      key: 'lastNameEn',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: true,
      ellipsis: true,
      render: (text: any) => <>{text || '-'}</>,
    },
    // {
    //   title: "Organization",
    //   dataIndex: "organizationName",
    //   key: "organizationName",
    //   sorter: true,
    //   ellipsis: true,
    // },
    // {
    //   title: "Department",
    //   dataIndex: "departmentName",
    //   key: "departmentName",
    //   sorter: true,
    //   ellipsis: true,
    // },
    // {
    //   title: "Position",
    //   dataIndex: "positionName",
    //   key: "positionName",
    //   sorter: true,
    //   ellipsis: true,
    // },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'userType',
      sorter: true,
      render: (text: any) => (
        <>
          {text === 1 ? <>Individual</> : text === 2 ? <>Enterprise</> : <>-</>}
        </>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      ellipsis: true,
      render: (text: any) => (
        <>{text === 1 ? <>Employee</> : text === 2 ? <>Customer</> : <>-</>}</>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text: any) => (
        <TextStatus active={text === STATUS.ACTIVE}>
          {text === STATUS.ACTIVE ? <>Active</> : <>Inactive</>}
        </TextStatus>
      ),
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createDate',
      sorter: true,
      render: (text: any) => dateStringToDatetime(text, 'en'),
    },
    {
      title: 'Last modified Date',
      dataIndex: 'updatedDate',
      key: 'updateDate',
      sorter: true,
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: any) => dateStringToDatetime(text, 'en'),
    },
  ];

  function handleOnRowSelectionChange(rowKeys: React.Key[]) {
    // console.log(rowKeys);
    setRowSelectedKeys(rowKeys.map((t: React.Key) => t?.toString()));
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TableData[]) => {
      // console.log(selectedRowKeys);
      handleOnRowSelectionChange(selectedRowKeys);
    },
  };

  const renderCreateUserModal = () => {
    return (
      <Modal
        visible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
        footer={null}
      >
        <ModalStyle.Container>
          <ModalStyle.Title>
            Which type of user do you want to create ?
          </ModalStyle.Title>
          <ModalStyle.Content>
            <Button
              onClick={() => history.push('/users/employee/create')}
              style={{ padding: '0px 24px' }}
            >
              Create Employee
            </Button>
            <ModalStyle.Desc>or</ModalStyle.Desc>
            <Button
              onClick={() => history.push('/users/customer/create')}
              style={{ padding: '0px 24px' }}
            >
              Create Customer
            </Button>
          </ModalStyle.Content>
        </ModalStyle.Container>
      </Modal>
    );
  };

  return (
    <LayoutMain>
      <PageHeader
        className="site-page-header"
        title="Users"
        extra={[
          <Button key={0} type="primary" icon={<DownloadOutlined />}>
            Import
          </Button>,
          <Button
            key={1}
            type="default"
            icon={<UserAddOutlined />}
            onClick={() => {
              setIsVisible(true);
            }}
          >
            Create New User
          </Button>,
        ]}
      />
      {renderCreateUserModal()}
      <ContentStyle>
        <Form {...layout} form={searchForm} onFinish={onFinish}>
          <Row gutter={[32, 0]}>
            <Col xs={7}>
              <Form.Item label="Search" name="name">
                <Input
                  placeholder="First Name or Last Name or Email"
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Form.Item label="Company" name="companyId">
                <Select>
                  <Select.Option key="999" value="">
                    All
                  </Select.Option>
                  {companiesOptions.map(
                    (data: CompaniesList.Entity, idx: number) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Form.Item label="Role" name="userTypes">
                <Select>
                  <Select.Option key="role_99" value={0}>
                    All
                  </Select.Option>
                  <Select.Option key="role_1" value={1}>
                    Individual
                  </Select.Option>
                  <Select.Option key="role_2" value={2}>
                    Enterprise
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Form.Item label="Type" name="type">
                <Select>
                  <Select.Option key="type_99" value={0}>
                    All
                  </Select.Option>
                  <Select.Option key="type_1" value={1}>
                    Employee
                  </Select.Option>
                  <Select.Option key="type_2" value={2}>
                    Customer
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Form.Item label="Status" name="status">
                <Select>
                  <Select.Option key="status_99" value="">
                    All
                  </Select.Option>
                  <Select.Option key={STATUS.ACTIVE} value={STATUS.ACTIVE}>
                    Active
                  </Select.Option>
                  <Select.Option key={STATUS.INACTIVE} value={STATUS.INACTIVE}>
                    Inactive
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <SearchPanelStyle>
                <Button htmlType="submit" type="primary">
                  Search
                </Button>
                &nbsp;
                <Button type="link" onClick={onClear}>
                  Clear
                </Button>
              </SearchPanelStyle>
            </Col>
          </Row>
        </Form>
        <div className="page-count-panel">
          <div className="page-count-label">
            {tableRange && (
              <>
                {`${tableRange?.start}-${tableRange?.end} of `}
                <span>{tableRange?.total || 0} </span> results
              </>
            )}
          </div>
          <div>
            <Button icon={<DownloadOutlined />}>
              Export
              {rowSelectedKeys.length > 0 && (
                <>{` (${rowSelectedKeys.length} items)`}</>
              )}
            </Button>
          </div>
        </div>
        <Table
          showSorterTooltip={false}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={tableData ? tableData : []}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: DEFAULT_PAGE.SIZE,
            defaultCurrent: DEFAULT_PAGE.NUMBER,
            current: tablePage?.pageNumber,
            pageSize: PageSize,
            total: tablePage?.total || 0,
          }}
          loading={isLoading}
        />
      </ContentStyle>
    </LayoutMain>
  );
}

const ModalStyle = {
  Container: styled.div`
    margin: 24px 0px;
  `,

  Title: styled.div`
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
  `,

  Content: styled.div`
    margin-bottom: 24px;
    text-align: center;
  `,
  Desc: styled.div`
    color: #525461;
    margin: 8px 0px;
  `,
};

const LinkStyle = styled.div`
  text-decoration: underline;
`;

const SearchPanelStyle = styled.div`
  display: flex;
  padding-top: 30px;
  height: 100%;
`;

const ContentStyle = styled.div`
  margin: 24px;
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(227, 228, 235, 0.25);
`;

const TextStatus = styled.div<{ active?: boolean }>`
  ${(props) =>
    props.active
      ? css`
          color: ${theme['@app-success']}; ;
        `
      : css`
          color: ${theme['@app-error']};
        `}
`;

export default Users;
