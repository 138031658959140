import React, { useEffect, useState, useCallback } from 'react';
import { Button, Row, Col, Input, Form, PageHeader, Table } from 'antd';
import { Link, useParams } from 'react-router-dom';
import LayoutMain from '@components/layout/Layout';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  DigitalCertificateDetail,
  Detail as CourseData,
  CertificateData,
} from '@services/models/digital-certificate-model';
import { digitalCertificateApi } from '@services/api';
import { calculateTableRange, mapStatusCode } from '@utils/data-utils';
import {
  DEFAULT_PAGE,
  STATUS,
  // DIRECTION,
  CERTIFICATE_STATUS,
} from '@constants/constants';
import { Pagination, TableRange } from '@services/models/common';
import { dateStringToDatetime, dayjsNowToDate } from '@utils/date-utils';
import * as theme from '@theme';

const PageSize = 10;
// const DEFAULT_SORTBY = "updateDate";

type TableData = {
  key: string;
  no: number;
  studentName: string;
  docUrl: string;
};

function Detail() {
  const { id }: any = useParams();
  const [searchForm] = Form.useForm();

  const [courseData, setCourseData] = useState<CourseData>();

  const [isLoadingDownloadAll, setIsLoadingDownloadAll] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tablePage, setTablePage] = useState<Pagination>();
  const [tableRange, setTableRange] = useState<TableRange>();
  const [
    tableRequest,
    setTableRequest,
  ] = useState<DigitalCertificateDetail.Request>({
      id,
      limit: { pageSize: PageSize, pageNumber: DEFAULT_PAGE.NUMBER },
      status: STATUS.ACTIVE,
      // sortBy: DEFAULT_SORTBY,
      // sortDirectionBy: "DESC",
    });

  const routes = [
    {
      path: '/digital-certificate',
      breadcrumbName: 'Digital Certificate',
    },
    {
      path: id,
      breadcrumbName: courseData?.code ?? '',
    },
  ];

  const getList = useCallback(async () => {
    try {
      setIsLoading(true);
      const result: DigitalCertificateDetail.Response =
        await digitalCertificateApi.digitalCertificateProcessDetail(
          tableRequest
        );
      // console.log('result : ', result);
      setTableData([]);
      if (result && result.page) {
        const page = result.page;
        if (result.data && result.data.length > 0) {
          const dataTable = result.data.map(
            (it: CertificateData, idx: number) => ({
              key: it.data.id,
              no: idx + 1,
              studentName: it.data.studentName,
              docUrl: it.info.docPublicUrl,
            })
          );
          // if (courseData?.batchName == null) {
          setCourseData(result.detail);
          // }
          setTableData(dataTable);
        }
        setTablePage({ ...page });
        setTableRange(
          calculateTableRange(
            result.page.pageSize || 0,
            result.page.pageNumber || 0,
            result.page.total,
            result.data?.length || 0
          )
        );
      }
    } catch (error) {
      setTableRange(undefined);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [tableRequest]);

  useEffect(() => {
    getList();
  }, [getList]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  // async function getList() {
  //   try {
  //     setIsLoading(true);
  //     const result: DigitalCertificateDetail.Response = await digitalCertificateApi.digitalCertificateProcessDetail(
  //       tableRequest
  //     );
  //     // console.log('result : ', result);
  //     setTableData([]);
  //     if (result && result.page) {
  //       const page = result.page;
  //       if (result.data && result.data.length > 0) {
  //         const dataTable = result.data.map(
  //           (it: CertificateData, idx: number) => ({
  //             key: it.data.id,
  //             no: idx + 1,
  //             studentName: it.data.studentName,
  //             docUrl: it.info.docPublicUrl,
  //           })
  //         );
  //         // if (courseData?.batchName == null) {
  //         setCourseData(result.detail);
  //         // }
  //         setTableData(dataTable);
  //       }
  //       setTablePage({ ...page });
  //       setTableRange(
  //         calculateTableRange(
  //           result.page.pageSize || 0,
  //           result.page.pageNumber || 0,
  //           result.page.total,
  //           result.data?.length || 0
  //         )
  //       );
  //     }
  //   } catch (error) {
  //     setTableRange(undefined);
  //     setIsLoading(false);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // console.log("sorter", sorter);
    // console.log("pagination", pagination);

    // const order = sorter.order ?? "descend";
    // const direction = DIRECTION[order as keyof typeof DIRECTION];
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: pagination.current,
      },
      // sortBy: sorter.order ? sorter.columnKey : DEFAULT_SORTBY,
      // sortDirectionBy: direction as "ASC" | "DESC",
    });
  };

  function onSearch() {
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: 1,
      },
      studentName: searchForm.getFieldValue('search'),
    });
  }

  const onFinish = (values: any) => {
    // console.log('Finish:', values);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '3%',
      ellipsize: true,
    },
    {
      title: 'Student Name',
      dataIndex: 'studentName',
      key: 'studentName',
      width: '15%',
    },
    {
      title: 'Action',
      dataIndex: 'docUrl',
      key: 'docUrl',
      width: '20%',
      ellipsize: true,
      render: (text: string) => (
        <LinkDonwloadStyle href={text}>Download</LinkDonwloadStyle>
      ),
    },
  ];

  const downloadAll = async () => {
    setIsLoadingDownloadAll(true);
    try {
      await digitalCertificateApi.downloadAll(
        id,
        (courseData?.batchName ?? 'BatchName') + '_' + dayjsNowToDate('en')
      );
    } catch (error) {
      setIsLoadingDownloadAll(false);
    } finally {
      setIsLoadingDownloadAll(false);
    }
  };

  // function downloadItems() {
  //   Modal.info({
  //     title: 'Coming soon!!',
  //     content: <div></div>,
  //     onOk() {},
  //   });
  // }

  return (
    <LayoutMain>
      <PageHeader
        breadcrumb={{
          routes,
          itemRender: (route: any) => (
            <Link to={route.path}>{route.breadcrumbName}</Link>
          ),
        }}
        className="site-page-header"
        onBack={() => window.history.back()}
        title={courseData?.code ?? '-'}
      />
      <ContentStyle>
        <Row>
          <CourseDetail.Section span={6}>
            <CourseDetail.Title>Batch name </CourseDetail.Title>
            <CourseDetail.Detail>{courseData?.batchName}</CourseDetail.Detail>
          </CourseDetail.Section>
          <CourseDetail.Section span={6}>
            <CourseDetail.Title>Create date </CourseDetail.Title>
            <CourseDetail.Detail>
              {courseData?.createDate
                ? dateStringToDatetime(courseData?.createDate, 'en')
                : ''}
            </CourseDetail.Detail>
          </CourseDetail.Section>
          {/* <CourseDetail.Section span={6}>
            <CourseDetail.Title>Approver </CourseDetail.Title>
            <CourseDetail.Detail>{courseData?.approver}</CourseDetail.Detail>
          </CourseDetail.Section> */}
          <CourseDetail.Section span={6}>
            <CourseDetail.Title>Status </CourseDetail.Title>
            <CourseDetail.Detail
              style={{
                color: courseData?.state
                  ? mapStatusCode(courseData?.state).color
                  : 'white',
              }}
            >
              {courseData?.state ? mapStatusCode(courseData?.state).text : '-'}
            </CourseDetail.Detail>
          </CourseDetail.Section>
        </Row>

        {courseData?.state === CERTIFICATE_STATUS.FAILED ? (
          <Row style={{ marginTop: '24px' }}>
            <CourseDetail.Section span={6}>
              <CourseDetail.Title>Failed Reason </CourseDetail.Title>
              <CourseDetail.Detail>
                {courseData?.errorMessage}
              </CourseDetail.Detail>
            </CourseDetail.Section>
          </Row>
        ) : (
          <></>
        )}
        {courseData?.state === CERTIFICATE_STATUS.REJECTED ? (
          <Row style={{ marginTop: '24px' }}>
            <CourseDetail.Section span={6}>
              <CourseDetail.Title>Rejected Reason </CourseDetail.Title>
              <CourseDetail.Detail>
                {courseData?.rejectReason}
              </CourseDetail.Detail>
            </CourseDetail.Section>
          </Row>
        ) : (
          <></>
        )}
      </ContentStyle>
      <ContentStyle>
        <Form {...layout} form={searchForm} onFinish={onFinish}>
          <Row gutter={[32, 32]} style={{ justifyContent: 'space-between' }}>
            <Col xs={5}>
              <Form.Item label="Search" name="search">
                <Input
                  suffix={<SearchOutlined />}
                  placeholder="Student name"
                  onPressEnter={onSearch}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className="page-count-panel">
          <div className="page-count-label">
            {tableRange && (
              <>
                {`${tableRange?.start}-${tableRange?.end} of `}
                <span>{tableRange?.total || 0} </span> results
              </>
            )}
          </div>
          <div>
            <Button
              icon={<DownloadOutlined />}
              onClick={downloadAll}
              loading={isLoadingDownloadAll}
            >
              Download All
            </Button>
          </div>
        </div>
        <Table
          showSorterTooltip={false}
          dataSource={tableData ? tableData : []}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: DEFAULT_PAGE.SIZE,
            defaultCurrent: DEFAULT_PAGE.NUMBER,
            current: tablePage?.pageNumber,
            pageSize: PageSize,
            total: tablePage?.total || 0,
            // onChange: handleTablePageChange,
          }}
          loading={isLoading}
        />
      </ContentStyle>
    </LayoutMain>
  );
}

const ContentStyle = styled.div`
  margin: 24px;
  padding: 24px 32px 32px 32px;
  background-color: white;
  box-shadow: 0px 2px 8px #e3e4eb, 25%;
`;
const LinkDonwloadStyle = styled.a`
  color: ${theme['@app-link']};
  text-decoration: underline;
`;

const CourseDetail = {
  Title: styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    padding-bottom: 8px;
    color: ${theme['@app-title-text']};
  `,
  Detail: styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${theme['@app-grey-2']};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  Section: styled(Col)`
    /* padding-bottom: 24px; */
  `,
};

export default Detail;
