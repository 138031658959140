import React, { useState } from 'react';
import { Button, Layout, Row, Col, Input, Form, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { userApi } from '@services/api';
import styled from 'styled-components';
import * as theme from '@theme';

function ResetPassword() {
  const history = useHistory();

  const [form] = Form.useForm();
  const { id }: any = history.location.state;

  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   console.log('init');
  // }, []);

  async function onFinish(values: any) {
    // console.log('onFinish:', values);
    try {
      setIsLoading(true);
      const result = await userApi.resetPasswordAdmin({
        id,
        password: values.password,
      });
      if (result.status) {
        notification.success({
          message: 'Successful Reset',
          description: 'Your password has been reset successfully.',
        });
        history.push('/login');
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message:
          'Sorry, we cannot reset your password right now. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <div style={{ height: '100%', display: 'flex' }}>
        <div className="app-login-bg-left">
          <div style={{ marginTop: '5%', marginLeft: '10%' }}>
            <Row gutter={[20, 10]} align="middle">
              <Col>
                <img
                  // src="/images/logo-pen.svg"
                  src={theme['@app-logo-url']}
                  width="85%"
                  height="85%"
                  alt=""
                />
              </Col>
              <Col>
                <div
                  style={{
                    fontSize: '30px',
                    fontWeight: 600,
                    color: `${theme['@secondary-color']}`,
                  }}
                >
                  Digital Certificate
                </div>
                <div style={{ fontSize: '20px', color: 'white' }}>
                  Enterprise Digital Certificate
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ marginLeft: '15%' }}>
            <ImageLeftStyle></ImageLeftStyle>
          </div>
        </div>
        <ContainerStyle.Box>
          <ContainerStyle.Content>
            <ContainerStyle.Title>Reset your password</ContainerStyle.Title>
            <ContainerStyle.Desc>
              Your new password must be different from <br />
              previous used password
            </ContainerStyle.Desc>

            <Form
              name="resetPassword"
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    // message: "Password is required",
                    message: '',
                  },
                  {
                    min: 8,
                    pattern: new RegExp(
                      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
                    ),
                    message: '',
                    // message:
                    //   "Password must be at least 8 charecters, at least one number, at least one upper case letter, at least one lower case letter",
                  },
                  {
                    max: 45,
                    message: '',
                    // message: "รหัสผ่านไม่เกิน 45 ตัวอักษร",
                  },
                ]}
              >
                <Input.Password placeholder="Password" size="large" />
              </Form.Item>

              <Form.Item noStyle shouldUpdate>
                {() =>
                  form.isFieldTouched('password') && (
                    <PasswordRequirementStyle.Box>
                      <PasswordRequirementStyle.Header>
                        Password requirements
                      </PasswordRequirementStyle.Header>
                      <ul>
                        <li>At least 8 charecters</li>
                        <li>At least one number</li>
                        <li>At least one upper case letter</li>
                        <li>At least one lower case letter</li>
                      </ul>
                    </PasswordRequirementStyle.Box>
                  )
                }
              </Form.Item>

              <Form.Item
                style={{ marginTop: '24px' }}
                label="Confirm Password"
                name="cfmPwd"
                rules={[
                  {
                    required: true,
                    message: 'Confirm Password is required',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Confirm Password does not match');
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm password" size="large" />
              </Form.Item>

              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%', borderRadius: '6px' }}
                    size="large"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                    loading={isLoading}
                  >
                    Reset Password
                  </Button>
                )}
              </Form.Item>
            </Form>
          </ContainerStyle.Content>
        </ContainerStyle.Box>
      </div>
    </Layout>
  );
}

const PasswordRequirementStyle = {
  Box: styled.div`
    margin-top: -12px;
    padding: 14px 18px 12px 18px;
    background: #f9faff;

    border: 1px dashed #bfc6dc;
    box-sizing: border-box;
    border-radius: 6px;

    > ul {
      color: ${theme['@app-success']};
      line-height: 22px;
    }
  `,
  Header: styled.div`
    line-height: 36px;
    color: #777a89;
  `,
};

const ImageLeftStyle = styled.div`
  width: 90%;
  height: 90%;
  float: right;
  position: fixed;
  top: 25%;
  /* background-image: url("/images/bg-login-full.svg"); */
  background-image: url(${theme['@app-login-bg-url']});
`;

const ContainerStyle = {
  Box: styled.div`
    height: 100%;
    background-color: white;
    width: 40%;
    display: flex;
    justify-items: center;

    /* background-image: url("/images/bg-blur-login.svg"); */
    background-image: url(${theme['@app-blur-bg-url']});
    z-index: 1;
  `,
  Content: styled.div`
    padding-left: 8%;
    position: absolute;
    top: 20%;
    left: 60%;
  `,
  Title: styled.div`
    font-size: 27px;
    font-weight: 500;
    color: ${theme['@app-title-text']};
    margin-bottom: 25px;
  `,
  Desc: styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${theme['@app-title-text']};
    margin-bottom: 40px;
  `,
};

export default ResetPassword;
