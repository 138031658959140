import React, { useEffect, useState } from 'react';
import { Upload, message, Button } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { fileExtension } from '@utils/file-utils';

const ACCEPT_LIST = [
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ptt',
  '.pptx',
  '.pdf',
  '.png',
  '.jpg',
  '.jpeg',
];
const IMG_TYPE = ['.png', '.jpg', '.jpeg', '.svg'];

type Props = {
  // uid: string;
  url?: string;

  disabled?: boolean;
  file?: File | null;
  displayName?: string;

  // uploading?: boolean;
  // progress?: number;

  onChange?: (file: File | null) => any;
  beforeUpload?: (file: RcFile, fileList: RcFile[]) => boolean;
  onRemove?: () => void;

  style?: React.CSSProperties;
  className?: string;

  // children?: React.ReactElement;
};

function FileUpload(props: Props) {
  const {
    // uid,

    url = '',
    displayName = '',
    disabled,

    onChange,
    beforeUpload,
    onRemove,

    // children,
  } = props;

  // const [previewUrl, setPreviewUrl] = useState(url);
  const [previewFile, setPreviewFile] = useState<Array<UploadFile<any>>>([]);
  // const [display, setDisplay] = useState(displayName);

  function defaultBeforeUpload(file: { type: string; size: number }) {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Flue must smaller than 10MB!');
    }
    return isLt10M;
  }

  function displayIcon(file: File) {
    const ext = fileExtension(file?.name || '').toLowerCase();
    // console.log(ext);
    // IMG
    if (IMG_TYPE.includes('.' + ext) && url) {
      return url;
    }

    // docx,pptx,xlsx etc.
    if (ACCEPT_LIST.includes('.' + ext)) {
      return '/images/icons/extensions/' + ext + '.svg';
    }

    // other case
    else {
      return '/images/icons/FileIcon.svg';
    }
  }

  const handleChange = (info: any) => {
    // console.log(info);
    onChange && onChange(info.file);
    setPreviewFile([
      {
        // prevent Uncaught Error: must set key for <rc-animate> children
        uid: info.file.uid,
        // url: previewUrl,
        name: info.file.name,
        status: 'done' as const,
        thumbUrl: displayIcon(info.file),
        size: info.file.size,
        type: info.file.type,
      },
    ]);
  };
  const handleRemove = (e: any) => {
    e.stopPropagation();
    onRemove && onRemove();
    setPreviewFile([]);
  };

  // useEffect(() => {
  //   setPreviewUrl(url);
  // }, [url]);

  useEffect(() => {
    if (!displayName) {
      return;
    }
    // const truncate = displayName.trim();
    // setDisplay(truncate.substring(0, 50) + (truncate.length > 50 ? "…" : ""));
  }, [displayName]);

  // useEffect(() => {
  //   console.log('preview file', previewFile);
  // }, [previewFile]);

  function displayProps() {
    if (previewFile) {
      return (
        <CustomPicturePreviewStyle
          disabled={disabled}
          multiple={false}
          listType="picture-card"
          showUploadList={false}
          fileList={previewFile}
          action={(f: File) => {
            // console.log(f);
            onChange && onChange(f);
            return '';
          }}
          beforeUpload={beforeUpload ?? defaultBeforeUpload}
          onChange={handleChange}
        >
          {previewFile.length > 0 ? (
            <>
              <ImageThumbStyle src={previewFile[0].thumbUrl}></ImageThumbStyle>
              <FileNameStyle>{previewFile[0].name}</FileNameStyle>
              <Action
                onClick={(e) => {
                  handleRemove(e);
                }}
                disabled={disabled}
              >
                <DeleteOutlined
                  style={{ fontSize: '24px', color: '#9CA2B5' }}
                />
              </Action>
            </>
          ) : (
            uploadButton
          )}
        </CustomPicturePreviewStyle>
      );
    } else {
      return (
        <CustomUploadStyle
          disabled={disabled}
          multiple={false}
          listType="picture-card"
          showUploadList={false}
          action={(f: File) => {
            // console.log(f);
            onChange && onChange(f);
            return '';
          }}
          beforeUpload={beforeUpload ?? defaultBeforeUpload}
          onChange={handleChange}
        >
          {uploadButton}
        </CustomUploadStyle>
      );
    }
  }
  const uploadButton = (
    <div>
      <UploadFormStyle.Container>
        <UploadFormStyle.Icon>
          <CloudUploadOutlined style={{ fontSize: '40px', color: '#BFC6DC' }} />
        </UploadFormStyle.Icon>
        <div style={{ textAlign: 'left' }}>
          <div style={{ color: '#363740' }}>Upload excel file here !</div>
          <div style={{ color: '#777A89', fontSize: '12px' }}>
            Maximum 10.0 MB and must be .xlsx file
          </div>
        </div>
      </UploadFormStyle.Container>
      <div>
        <UploadFormStyle.ButtonUpload size="small">
          Choose File
        </UploadFormStyle.ButtonUpload>
      </div>
    </div>
  );

  return <>{displayProps()}</>;
}

const FileNameStyle = styled.div`
  width: 100%;
  text-align: left;
  margin-left: 100px;
`;

const Action = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;

  position: relative;

  font-weight: 300;
  font-size: 12px;
  line-height: 24px;

  user-select: none;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      display: none;
    `}
`;

const ImageThumbStyle = styled.img`
  width: 85px;
  height: 85px;
  position: absolute;
  left: 10px;
`;

const UploadFormStyle = {
  Container: styled.div`
    text-align: center;
    display: inline-flex;
  `,
  Icon: styled.div`
    margin-right: 10px;
  `,
  ButtonUpload: styled(Button)`
    margin-top: 12px;
    border-radius: 6px;
    padding: 0px 20px;
  `,
};

const CustomUploadStyle = styled(Upload)`
  > .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 0px;

    background-color: #f6f7fa;
  }
`;

const CustomPicturePreviewStyle = styled(Upload)`
  > .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 135px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
  .ant-upload-list-item-info {
    > span {
      display: flex;
      flex-direction: row;
    }
  }
  .ant-upload-list-item-thumbnail {
    /* margin: 15px 0px 0px 15px; */
    width: 80px;
    height: 80px;
    position: relative;
    object-fit: cover;
    top: 15px;
    :hover + PopoverImg {
    }
  }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
  }
  .ant-upload-list-item-name {
    margin: auto;
    padding-left: 40px;
    padding-right: 0;
  }
  .ant-upload-list-item-card-actions {
    position: static;
    width: 36px;
    margin: 0;
    display: flex;
    align-items: center;
    .anticon.anticon-delete {
      margin-right: 0px;
      padding-right: 0px;
    }
  }
  > * .anticon.anticon-download {
    display: none;
  }

  > * .anticon.anticon-delete {
    margin-right: 16px;
  }

  > * .ant-upload-list-item-name {
    color: #00aa84;
  }
`;

export default FileUpload;
