import React, { useState } from 'react';
import { Button, Layout, Row, Col, Input, Form, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserLogin } from '@services/models/user-model';
import { userApi } from '@services/api';
import { menu } from '@constants/menu';
import * as theme from '@theme';
import styled from 'styled-components';
import { UserLoginInfo, UserToken } from '@services/models/common';
import * as userLoginUtils from '@utils/user-login-utils';

function Login() {
  userLoginUtils.logout();

  const [form] = Form.useForm();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  async function onFinish(values: any) {
    try {
      setIsLoading(true);
      setIsError(false);
      const toMenu = menu[0];
      const response: UserLogin.Response = await userApi.login(
        values.username!,
        values.password!
      );
      if (response.status) {
        // console.log('Success:', response.data);

        const responseInfo = response.data;

        const token: UserToken = {
          accessToken: response.token.accessToken
            ? response.token.accessToken
            : '',
          refreshToken: response.token.refreshToken
            ? response.token.refreshToken
            : '',
        };
        const userLoginInfo: UserLoginInfo = {
          id: responseInfo.id,
          code: responseInfo.code,
          companyName: responseInfo.companyName,
          companyId: responseInfo.companyId,
          displayName: responseInfo.displayName,
          email: responseInfo.email,
          loginName: responseInfo.loginName,
          firstNameEn: responseInfo.firstNameEn,
          lastNameEn: responseInfo.lastNameEn,
          firstNameTh: responseInfo.firstNameTh,
          lastNameTh: responseInfo.lastNameTh,
          latestLoginDate: responseInfo.latestLoginDate,
          token,
        };

        userLoginUtils.setUserLoginInfo(userLoginInfo);

        sessionStorage.setItem('tab', toMenu.key);
        history.push(toMenu.route);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <div style={{ height: '100%', display: 'flex' }}>
        <div className="app-login-bg-left">
          <div style={{ marginTop: '5%', marginLeft: '10%' }}>
            <Row gutter={[20, 10]} align="middle">
              <Col>
                <img
                  // src="/images/logo-pen.svg"
                  src={theme['@app-logo-url']}
                  width="85%"
                  height="85%"
                  alt=""
                />
              </Col>
              <Col>
                <div
                  style={{
                    fontSize: '30px',
                    fontWeight: 600,
                    color: `${theme['@secondary-color']}`,
                  }}
                >
                  Digital Certificate
                </div>
                <div style={{ fontSize: '20px', color: 'white' }}>
                  Enterprise Digital Certificate
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ marginLeft: '15%' }}>
            <ImageLeftStyle></ImageLeftStyle>
          </div>
        </div>
        <ContainerStyle.Box>
          <ContainerStyle.Content>
            {isError && (
              <div style={{ marginBottom: '18px' }}>
                <Alert
                  message="Incorrect Username or Password."
                  type="error"
                  showIcon
                />
              </div>
            )}
            <ContainerStyle.Title>
              Log into <br />
              Digital Certificate Admin
            </ContainerStyle.Title>
            <Form
              name="login"
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: 'Please input your username!' },
                ]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%', borderRadius: '6px' }}
                  size="large"
                  loading={isLoading}
                >
                  Login
                </Button>
              </Form.Item>

              <div style={{ textAlign: 'center' }}>
                <Button
                  type="link"
                  onClick={() => history.push('/forgot-password')}
                >
                  Forgot password?
                </Button>
              </div>
            </Form>
          </ContainerStyle.Content>
        </ContainerStyle.Box>
      </div>
    </Layout>
  );
}

const ImageLeftStyle = styled.div`
  width: 90%;
  height: 90%;
  float: right;
  position: fixed;
  top: 25%;
  fill: 'black';
  background-image: url(${theme['@app-login-bg-url']});
  /* background-image: url("/images/bg-login-full.svg"); */
`;

const ContainerStyle = {
  Box: styled.div`
    height: 100%;
    background-color: white;
    width: 40%;
    display: flex;
    justify-items: center;

    background-image: url(${theme['@app-blur-bg-url']});
    /* background-image: url("/images/bg-blur-login.svg"); */
    z-index: 1;
  `,
  Content: styled.div`
    padding-left: 8%;
    position: absolute;
    top: 20%;
    left: 60%;

    width: 480px;
  `,
  Title: styled.div`
    font-size: 27px;
    font-weight: 500;
    color: ${theme['@app-title-text']};
    margin-bottom: 40px;
  `,
};

export default Login;
