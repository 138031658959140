import { User } from '@services/models/user-model';
import React, { cloneElement } from 'react';

import { UserProvider } from './UserContext';

function ProviderComposer({ contexts, children }: any) {
  return contexts.reduce(
    (kids: any, parent: any) =>
      cloneElement(parent, {
        children: kids,
      }),
    children
  );
}
export default function ContextProvider({ children, data }: any) {
  return (
    <ProviderComposer
      // add providers to array of contexts
      contexts={[<UserProvider data={data as User} />]}
    >
      {children}
    </ProviderComposer>
  );
}
