import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as userLoginUtils from '@utils/user-login-utils';

function authInterceptor(config: AxiosRequestConfig) {
  if (userLoginUtils.isLogedIn()) {
    const key = 'Authorization';
    config.headers[key] = `Bearer ${
      userLoginUtils.getUserToken()?.accessToken
    }`;
  }
}

function registerInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(
    (config) => {
      authInterceptor(config);
      return config;
    },
    (error) => {
      if (error.response.status === 401) {
        userLoginUtils.logout();
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

class ApiClient {
  static getAxioInstance() {
    if (!this.axiosInstance) {
      // console.log('CREATE AxiosInstance');

      this.axiosInstance = axios.create();
      registerInterceptors(this.axiosInstance);
    }
    return this.axiosInstance;
  }

  static getAxioExternalInstance() {
    if (!this.axiosExternalInstance) {
      // console.log('CREATE AxiosInstance');

      this.axiosExternalInstance = axios.create();
    }
    return this.axiosExternalInstance;
  }
  private static axiosInstance?: AxiosInstance;
  private static axiosExternalInstance?: AxiosInstance;
}

const api = ApiClient.getAxioInstance();
const apiExternal = ApiClient.getAxioExternalInstance();

export { api, apiExternal };
