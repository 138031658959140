import React from 'react';
import LayoutMain from '@components/layout/Layout';

function Home() {
  // React.useEffect(() => {
  //   console.log('user', user);
  // }, [user]);

  return <LayoutMain>5555</LayoutMain>;
}

export default Home;

// import React, { useEffect, useState } from "react";
// import styled, { css } from "styled-components";
// import {
//   Button,
//   PageHeader,
//   Row,
//   Col,
//   Checkbox,
//   Input,
//   Form,
//   Table,
//   Select,
// } from "antd";
// import { useHistory } from "react-router-dom";
// import LayoutMain from "@components/layout/Layout";
// import { UserAddOutlined, DownloadOutlined } from "@ant-design/icons";

// const { Option } = Select;

// type TableData = {
//   key: string;
//   employeeId: string;
//   firstNameEn: string;
//   lastNameEn: string;
//   email: string;
//   company: string;
//   organization: string;
//   department: string;
//   position: string;
//   role: string;
//   createdDate: string;
//   updatedDate: string;
// };

// const PageSize = 10;

// function Home() {
//   let history = useHistory();

//   const layout = {
//     labelCol: { span: 24 },
//     wrapperCol: { span: 24 },
//   };

//   const [searchForm] = Form.useForm();
//   const [rowSelectedKeys, setRowSelectedKeys] = useState<string[]>([]);
//   const [userList, setUserList] = useState<TableData[]>([]);

//   useEffect(() => {
//     console.log("init");
//     searchForm.setFieldsValue({
//       company: "All",
//     });
//     const options = {
//       limit: { pageSize: PageSize, pageNumber: 1 },
//       status: "A",
//     };
//     // getList(options);
//   }, []);

//   const onFinish = (values: any) => {
//     console.log("Finish:", values);
//   };

//   const onFinishFailed = (errorInfo: any) => {
//     console.log("Failed:", errorInfo);
//   };

//   function handleChange(value: any) {
//     console.log(`selected ${value}`);
//   }

//   const data = [
//     {
//       key: "007840281",
//       employeeId: "007840281",
//       firstNameEn: "Rawiwan",
//       lastNameEn: "Weeraprasit",
//       email: "rawiwan@iamconsulting.co.th",
//       company: "I Am Consulting",
//       organization: "-",
//       department: "Blockchain",
//       position: "Developer",
//       role: "-",
//       createdDate: "05.03.2021 11:48",
//       updatedDate: "05.03.2021 11:48",
//     },
//     {
//       key: "007840282",
//       employeeId: "007840282",
//       firstNameEn: "Rawiwan",
//       lastNameEn: "Weeraprasit",
//       email: "rawiwan@iamconsulting.co.th",
//       company: "I Am Consulting",
//       organization: "-",
//       department: "Blockchain",
//       position: "Developer",
//       role: "-",
//       createdDate: "05.03.2021 11:48",
//       updatedDate: "05.03.2021 11:48",
//     },
//     {
//       key: "007840283",
//       employeeId: "007840283",
//       firstNameEn: "Rawiwan",
//       lastNameEn: "Weeraprasit",
//       email: "rawiwan@iamconsulting.co.th",
//       company: "I Am Consulting",
//       organization: "-",
//       department: "Blockchain",
//       position: "Developer",
//       role: "-",
//       createdDate: "05.03.2021 11:48",
//       updatedDate: "05.03.2021 11:48",
//     },
//   ];

//   const columns = [
//     {
//       title: "Employee ID",
//       dataIndex: "employeeId",
//       key: "employeeId",
//     },
//     {
//       title: "First Name (EN)",
//       dataIndex: "firstNameEn",
//       key: "firstNameEn",
//     },
//     {
//       title: "Last Name (EN)",
//       dataIndex: "lastNameEn",
//       key: "lastNameEn",
//     },
//     {
//       title: "Email",
//       dataIndex: "email",
//       key: "email",
//     },
//     {
//       title: "Company",
//       dataIndex: "company",
//       key: "company",
//     },
//     {
//       title: "Organization",
//       dataIndex: "organization",
//       key: "organization",
//     },
//     {
//       title: "Department",
//       dataIndex: "department",
//       key: "department",
//       sorter: true,
//     },
//     {
//       title: "Position",
//       dataIndex: "position",
//       key: "position",
//     },
//     {
//       title: "Role",
//       dataIndex: "role",
//       key: "role",
//     },
//     {
//       title: "Created Date",
//       dataIndex: "createdDate",
//       key: "createdDate",
//     },
//     {
//       title: "Last modified Date",
//       dataIndex: "updatedDate",
//       key: "updatedDate",
//     },
//   ];

//   function handleOnRowSelectionChange(rowKeys: React.Key[]) {
//     // console.log(rowKeys);
//     setRowSelectedKeys(rowKeys.map((t: React.Key) => t?.toString()));
//   }

//   const rowSelection = {
//     onChange: (selectedRowKeys: React.Key[], selectedRows: TableData[]) => {
//       handleOnRowSelectionChange(selectedRowKeys);
//     },
//     getCheckboxProps: (record: TableData) => ({
//       disabled: false,
//     }),
//   };

//   return (
//     // <LayoutMain>
//     <>
//       <PageHeaderStyle title="Users" />

//       <ContentStyle>
//         <Form {...layout} form={searchForm} onFinish={onFinish}>
//           <Row gutter={[32, 32]}>
//             <Col xs={5}>
//               <Form.Item label="Search" name="search">
//                 <Input placeholder="Name" />
//               </Form.Item>
//             </Col>
//             <Col xs={5}>
//               <Form.Item label="Company" name="company">
//                 <Select onChange={handleChange}>
//                   <Option value="All">All</Option>
//                   <Option value="IAM">I Am Consulting</Option>
//                   <Option value="ABC">ACB Company</Option>
//                 </Select>
//               </Form.Item>
//             </Col>
//             <Col xs={14}>
//               <Form.Item label="Role" name="role">
//                 <Checkbox.Group
//                   options={[
//                     { label: "Individual", value: "Individual" },
//                     { label: "Enterprise", value: "Enterprise" },
//                     { label: "Admin", value: "Admin" },
//                   ]}
//                   onChange={handleChange}
//                 />
//               </Form.Item>
//             </Col>
//           </Row>
//         </Form>
//         <div className="page-count-panel">
//           <div className="page-count-label">
//             1-12 of <span>3,576 </span> results
//           </div>
//           <div>
//             <Button icon={<DownloadOutlined />}>Download</Button>
//           </div>
//         </div>
//         <Table
//           rowSelection={{
//             type: "checkbox",
//             ...rowSelection,
//           }}
//           dataSource={data}
//           // dataSource={[]}
//           columns={columns}
//         />
//       </ContentStyle>

//       {/* </LayoutMain> */}
//     </>
//   );
// }

// const PageHeaderStyle = styled(PageHeader)`
//   padding: "24px";

//   .ant-page-header-heading
//     > .ant-page-header-heading-left
//     > .ant-page-header-heading-title {
//     line-height: 40px;
//   }
// `;

// const ContentStyle = styled.div`
//   margin: 24px;
//   padding: 24px 32px 32px 32px;
//   background-color: #fff;
//   box-shadow: 0px 2px 8px rgba(227, 228, 235, 0.25);
// `;

// export default Home;
