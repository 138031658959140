import config from '@services/config';

export const URL = config.baseApi;
export const BASE = `${URL}/eds`;

// master-data-api
export const MASTER_DATA_BASE_URL = `${BASE}/master-data`;

// document-api
export const DOC_BASE_URL = `${BASE}/documents`;
export const DOC_INFO = `${BASE}/documents/info`;

// digital-certificate-api
export const DIGITAL_CERTIFICATE_BASE_URL = `${BASE}/digital-certificate`;
export const DIGITAL_CERTIFICATE_PROCESS = `${DIGITAL_CERTIFICATE_BASE_URL}/process`;
export const DIGITAL_CERTIFICATE_PROCESS_LIST = `${DIGITAL_CERTIFICATE_BASE_URL}/process-list`;
export const DIGITAL_CERTIFICATE_LIST = `${DIGITAL_CERTIFICATE_BASE_URL}/list`;
export const DIGITAL_CERTIFICATE_PROCESS_DETAIL = `${DIGITAL_CERTIFICATE_BASE_URL}/process-detail`;
export const DIGITAL_CERTIFICATE_INFO = `${DIGITAL_CERTIFICATE_BASE_URL}/certificate-info`;
export const DIGITAL_CERTIFICATE_TEMPLATE_LIST = `${DIGITAL_CERTIFICATE_BASE_URL}/template-list`;
export const DIGITAL_CERTIFICATE_DOWNLOAD = `${DIGITAL_CERTIFICATE_BASE_URL}/download`;

// users-api
export const USERS_BASE_URL = `${BASE}/users`;
export const USERS_LIST = `${USERS_BASE_URL}/list`;
export const USERS_LOGIN = `${USERS_BASE_URL}/admin/login`;

export const USERS_ADMIN_BASE_URL = `${USERS_BASE_URL}/admin`;
export const USERS_ADMIN_LIST = `${USERS_ADMIN_BASE_URL}/list`;
export const USERS_ADMIN_FORGOT_PASSWORD = `${USERS_ADMIN_BASE_URL}/forgot-password`;
export const USERS_ADMIN_CHECK_URL_REF = `${USERS_ADMIN_BASE_URL}/check-url-ref`;
export const USERS_ADMIN_RESET_PASSWORD = `${USERS_ADMIN_BASE_URL}/reset-password`;

export const USERS_CUSTOMER_BASE_URL = `${USERS_BASE_URL}/customer`;

// companies-api
export const COMPANIES_BASE_URL = `${BASE}/companies`;
export const COMPANIES_LIST = `${COMPANIES_BASE_URL}/list`;
