import React from 'react';
import {
  UserOutlined,
  SolutionOutlined,
  TeamOutlined,
} from '@ant-design/icons';

export const menu = [
  // {
  //   key: 'users',
  //   name: 'Users',
  //   route: '/users',
  //   icon: <TeamOutlined />,
  // },
  {
    key: 'digital-certificate',
    name: 'Certificate Batch',
    route: '/digital-certificate',
    icon: <SolutionOutlined />,
  },
  {
    key: 'certificates',
    name: 'Certificate List',
    route: '/certificates',
    icon: <SolutionOutlined />,
  },
  {
    key: 'user-admin',
    name: 'User Admin',
    route: '/user-admin',
    icon: <UserOutlined />,
  },
];
