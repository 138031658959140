import ProviderComposer from '@contexts/ProviderComposer';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import * as userLoginUtils from '@utils/user-login-utils';

export default function Authen(
  props: RouteComponentProps<any>,
  children: React.ReactNode
) {
  const result = userLoginUtils.isLogedIn();
  const user = userLoginUtils.getUserInfo();
  // const userStr = localStorage.getItem('userinfo');
  // let user = {} as any;

  // if (userStr) {
  //   user = JSON.parse(userStr);
  //   if (!user.loginName) {
  //     result = false;
  //   }
  // } else {
  //   result = false;
  // }

  if (result) {
    return <ProviderComposer data={user}>{children}</ProviderComposer>;
  } else {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    );
  }
}
