import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Row,
  Col,
  Input,
  Form,
  PageHeader,
  Select,
  Table,
  Modal,
  notification,
  Tabs,
} from 'antd';
import LayoutMain from '@components/layout/Layout';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import FileUpload from '@components/upload-file/FileUpload';
import { digitalCertificateApi } from '@services/api';
import {
  DEFAULT_PAGE,
  STATUS,
  DIRECTION,
  CERTIFICATE_STATUS,
} from '@constants/constants';
import {
  BatchList,
  TemplateList,
  BatchCreate,
} from '@services/models/digital-certificate-model';
import { dateStringToDatetime } from '@utils/date-utils';
import { mapStatusCode, calculateTableRange } from '@utils/data-utils';
import { Pagination, TableRange } from '@services/models/common';
import { SortOrder } from 'antd/lib/table/interface';
import * as theme from '@theme';
import * as userLoginUtils from '@utils/user-login-utils';

type TableData = {
  key: string;
  code: string;
  batchName: string;
  templateName: string;
  records: number;
  createdDate: Date;
  updatedDate: Date;
  issueDate: Date;
  status: number;
  docPublicUrl: string;
  createdBy: string;
};

const PageSize = 10;
const DEFAULT_SORTBY = 'updateDate';
const { TabPane } = Tabs;

function DigitalCertification() {
  const [searchForm] = Form.useForm();
  const [uploadForm] = Form.useForm();
  const [templateForm] = Form.useForm();

  const [modalLoading, setModalLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tablePage, setTablePage] = useState<Pagination>();
  const [tableRange, setTableRange] = useState<TableRange>();
  const [tableRequest, setTableRequest] = useState<BatchList.Request>({
    limit: { pageSize: PageSize, pageNumber: 1 },
    status: STATUS.ACTIVE,
    sortBy: DEFAULT_SORTBY,
    sortDirectionBy: 'DESC',
  });

  const [templatesOptions, setTemplatesOptions] = useState<
    TemplateList.Entity[]
  >([]);

  const userInfo = userLoginUtils.getUserInfo();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const initData = useCallback(async () => {
    try {
      setIsLoading(true);

      searchForm.setFieldsValue({
        state: 0,
      });

      const templates = await digitalCertificateApi.templateList();
      setTemplatesOptions(templates.data);
    } catch (error) {
      setTemplatesOptions([]);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [searchForm]);

  const getList = useCallback(async () => {
    try {
      setIsLoading(true);
      const result: BatchList.Response =
        await digitalCertificateApi.processList(tableRequest);
      if (result && result.page) {
        setTablePage(result.page);
        if (result.data && result.data.length > 0) {
          const dataTable = result.data.map(
            (it: BatchList.Data, idx: number) => ({
              key: it.data.id,
              code: it.data.code,
              batchName: it.data.batchName,
              templateName: it.data.templateName,
              records: it.data.totalRecord,
              createdDate: it.data.createDate,
              updatedDate: it.data.updateDate,
              issueDate: it.data.createDate,
              status: it.data.state,
              docPublicUrl: it.info.docPublicUrl,
              createdBy: it.data.firstNameEn + " " + it.data.lastNameEn,
            })
          );
          setTableData(dataTable);
          setTableRange(
            calculateTableRange(
              result.page.pageSize || 0,
              result.page.pageNumber || 0,
              result.page.total,
              result.data.length
            )
          );
        } else {
          setTableData([]);
          setTableRange(undefined);
        }
      } else {
        setTableData([]);
        setTableRange(undefined);
      }
    } catch (error) {
      setTableRange(undefined);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [tableRequest]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    getList();
  }, [getList]);

  const onFinish = (values: any) => {
    // console.log('Finish:', values);
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: 1,
      },
      batchName: values.batchName,
      state: Number(values.state) ?? 0,
    });
  };

  /////////////// START Modal //////////////////////

  const onFinishCreatation = async (values: any) => {
    // console.log('Finish:', values);
    try {
      setModalLoading(true);
      const result: BatchCreate.Response = await digitalCertificateApi.process({
        file: values.uploadFile,
        batchName: values.batchName,
        templateId: values.templateId,
        // approveId: values.approverId,
        userId: userInfo?.id ?? 'unknown',
      });
      if (result) {
        if (result.status) {
          setIsVisible(false);
          uploadForm.resetFields();
          templateForm.resetFields();

          notification.success({
            message: 'Your certificate batch was sucessfully created.',
          });

          setTableRequest({
            ...tableRequest,
            limit: {
              ...tableRequest.limit,
              pageNumber: 1,
            },
          });
        } else {
          // fail validation
          Modal.error({
            title: 'Validate failed',
            content: (
              <>
                You can download error messages in excel file{' '}
                <LinkDonwloadStyle href={result.errorUrl}>
                  here
                </LinkDonwloadStyle>
                .
              </>
            ),
          });
        }
      }
    } catch (error) {
      Modal.error({
        title: 'Upload failed',
        content: 'Templete excel file format is not correct',
      });
      setModalLoading(false);
    } finally {
      setModalLoading(false);
    }
  };

  const onClear = () => {
    searchForm.resetFields();
    searchForm.setFieldsValue({
      state: 0,
    });
    setTableRequest({
      limit: { pageSize: PageSize, pageNumber: 1 },
      status: STATUS.ACTIVE,
      sortBy: DEFAULT_SORTBY,
      sortDirectionBy: 'DESC',
    });
  };

  const openPreview = (form: any) => {
    const templateSelected = templatesOptions.find(
      (it) => it.id === form.getFieldValue('templateId')
    );
    window.open(
      templateSelected?.exportExampleFileUrl ?? '',
      '_blank' // <- This is what makes it open in a new window.
    );
  };

  const onFinishTemplate = async (values: any) => {
    // console.log('Finish:', values);
    try {
      setModalLoading(true);
      const templateSelected = templatesOptions.find(
        (it: TemplateList.Entity) => it.id === values.templateId
      );
      if (templateSelected && templateSelected.templateExcelFileUrl) {
        await digitalCertificateApi.downloadTemplate(
          templateSelected.templateExcelFileUrl,
          templateSelected.templateExcelFileName
        );
      } else {
        Modal.error({
          title: 'Download template failed',
          content: 'Templete excel file is not exists.',
        });
      }
    } catch (error) {
      Modal.error({
        title: 'Download template failed',
        content: 'Templete excel file is not exists.',
      });
      setModalLoading(false);
    } finally {
      setModalLoading(false);
    }
  };

  function renderBatchModal() {
    return (
      <Modal
        visible={isVisible}
        onCancel={() => {
          setIsVisible(false);
          uploadForm.resetFields();
          templateForm.resetFields();
        }}
        footer={null}
      >
        <ModalStyle.Title>Create Certificate Batch</ModalStyle.Title>
        <ModalStyle.Content>
          <Tabs defaultActiveKey="2">
            <TabPane tab="Download excel templete" key="1">
              <ModalStyle.Desc>
                Excel templete used for uploading digital certificate.
              </ModalStyle.Desc>
              <Form {...layout} form={templateForm} onFinish={onFinishTemplate}>
                <Form.Item label="Template Name" required>
                  <Form.Item
                    noStyle
                    name="templateId"
                    rules={[
                      {
                        required: true,
                        message: 'Template Name is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Template Name"
                      style={{ width: '70%' }}
                    >
                      {templatesOptions.map(
                        (data: TemplateList.Entity, idx: number) => (
                          <Select.Option key={idx} value={data.id}>
                            {data.templateName}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <Button
                        style={{ width: '27%', marginLeft: '3%' }}
                        disabled={
                          !templateForm.isFieldTouched('templateId') &&
                          !templateForm.getFieldValue('templateId')
                        }
                        onClick={() => openPreview(templateForm)}
                      >
                        Preview Cert.
                      </Button>
                    )}
                  </Form.Item>
                </Form.Item>

                <Row>
                  <Col xs={24} style={{ textAlign: 'right' }}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={() => setIsVisible(true)}
                      loading={modalLoading}
                      style={{ padding: '0px 24px' }}
                    >
                      Download
                    </Button>
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane tab="Upload" key="2">
              <ModalStyle.Desc>
                Upload edited excel templete. You can download the excel
                templete at “Download excel templete” tab.
              </ModalStyle.Desc>
              <Form {...layout} form={uploadForm} onFinish={onFinishCreatation}>
                <Form.Item label="Template Name" required>
                  <Form.Item
                    noStyle
                    name="templateId"
                    rules={[
                      {
                        required: true,
                        message: 'Template Name is required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Template Name"
                      style={{ width: '70%' }}
                    >
                      {templatesOptions.map(
                        (data: TemplateList.Entity, idx: number) => (
                          <Select.Option key={idx} value={data.id}>
                            {data.templateName}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <Button
                        style={{ width: '27%', marginLeft: '3%' }}
                        disabled={
                          !uploadForm.isFieldTouched('templateId') &&
                          !uploadForm.getFieldValue('templateId')
                        }
                        onClick={() => openPreview(uploadForm)}
                      >
                        Preview Cert.
                      </Button>
                    )}
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  style={{ paddingTop: '8px' }}
                  name="uploadFile"
                  rules={[
                    {
                      required: true,
                      message: 'file is required',
                    },
                  ]}
                >
                  <FileUpload
                    // uid={"uploadFile"}
                    beforeUpload={() => false}
                    onChange={(file) => {
                      if (file) {
                        if (
                          file.type ===
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        ) {
                          uploadForm.setFieldsValue({
                            uploadFile: file,
                          });
                          uploadForm.setFields([
                            {
                              name: 'uploadFile',
                              errors: [],
                            },
                          ]);
                        }
                      }
                      // else {
                      //   console.log('no file');
                      // }
                    }}
                    onRemove={() => {
                      uploadForm.setFieldsValue({
                        uploadFile: undefined,
                      });
                    }}
                  ></FileUpload>
                </Form.Item>
                <Form.Item
                  label="Batch Name"
                  name="batchName"
                  rules={[
                    {
                      required: true,
                      message: 'Name is required',
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Row>
                  <Col xs={24} style={{ textAlign: 'right' }}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={() => setIsVisible(true)}
                      loading={modalLoading}
                      style={{ padding: '0px 24px' }}
                    >
                      Upload
                    </Button>
                  </Col>
                </Row>
              </Form>
            </TabPane>
          </Tabs>
        </ModalStyle.Content>
      </Modal>
    );
  }

  /////////////// END Modal //////////////////////

  const columns = [
    {
      title: 'Batch ID',
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      render: (text: string, record: TableData) =>
        record.status === CERTIFICATE_STATUS.COMPLETED ? (
          <LinkStyle>
            <Link to={'/digital-certificate/' + record.key}>
              {text || record.key}
            </Link>
          </LinkStyle>
        ) : (
          <>{text || record.key}</>
        ),
    },
    {
      title: 'Batch Name',
      dataIndex: 'batchName',
      key: 'batchName',
      sorter: true,
    },
    {
      title: 'Template Name',
      dataIndex: 'templateName',
      key: 'templateName',
      sorter: true,
    },
    {
      title: 'Records',
      dataIndex: 'records',
      key: 'totalRecord',
      sorter: true,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createDate',
      sorter: true,
      render: (text: string) => dateStringToDatetime(text, 'en'),
    },
    {
      title: 'Last Modified Date',
      dataIndex: 'updatedDate',
      key: 'updateDate',
      sorter: true,
      defaultSortOrder: 'descend' as SortOrder,
      render: (text: string) => dateStringToDatetime(text, 'en'),
    },
    {
      title: "Create by",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'state',
      sorter: true,
      render: (text: string) => textStatus(text),
    },
    {
      title: 'Action',
      dataIndex: 'docPublicUrl',
      key: 'docPublicUrl',
      render: (text: string) => (
        <LinkDonwloadStyle href={text}>Download</LinkDonwloadStyle>
      ),
    },
  ];

  function textStatus(state: string) {
    const statusDetail = mapStatusCode(Number(state));
    return (
      <div style={{ color: statusDetail?.color }}>{statusDetail?.text}</div>
    );
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // console.log("sorter", sorter);
    // console.log("pagination", pagination);

    const order = sorter.order ?? 'descend';
    const direction = DIRECTION[order as keyof typeof DIRECTION];
    setTableRequest({
      ...tableRequest,
      limit: {
        ...tableRequest.limit,
        pageNumber: pagination.current,
      },
      sortBy: sorter.order ? sorter.columnKey : DEFAULT_SORTBY,
      sortDirectionBy: direction as 'ASC' | 'DESC',
    });
  };

  function createSelectItems() {
    const items = [];
    for (let i = 0; i <= 60; i += 10) {
      if (i !== 10 && i !== 20) {
        items.push(
          <Select.Option key={i} value={i}>
            {mapStatusCode(i).text}
          </Select.Option>
        );
      }
      // here I will be creating my options dynamically based on
      // what props are currently passed to the parent component
    }
    return items;
  }

  return (
    <LayoutMain>
      <PageHeader
        className="site-page-header"
        // onBack={() => window.history.back()}
        title="Certificate Batch"
        extra={[
          <Button
            key={1}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsVisible(true)}
          >
            Create
          </Button>,
        ]}
      />
      {renderBatchModal()}
      <ContentStyle>
        <Form {...layout} form={searchForm} onFinish={onFinish}>
          <Row gutter={[32, 32]}>
            <Col xs={5}>
              <Form.Item label="Batch Name" name="batchName">
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={5}>
              <Form.Item label="Status" name="state">
                <Select>{createSelectItems()}</Select>
              </Form.Item>
            </Col>
            <Col>
              <SearchPanelStyle>
                <Button htmlType="submit" type="primary">
                  Search
                </Button>
                &nbsp;
                <Button type="link" onClick={onClear}>
                  Clear
                </Button>
              </SearchPanelStyle>
            </Col>
          </Row>
        </Form>

        <div className="page-count-panel">
          <div className="page-count-label">
            {tableRange && (
              <>
                {`${tableRange?.start}-${tableRange?.end} of `}
                <span>{tableRange?.total || 0} </span> results
              </>
            )}
          </div>
        </div>
        <Table
          showSorterTooltip={false}
          dataSource={tableData ? tableData : []}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: DEFAULT_PAGE.SIZE,
            defaultCurrent: DEFAULT_PAGE.NUMBER,
            current: tablePage?.pageNumber,
            pageSize: PageSize,
            total: tablePage?.total || 0,
          }}
          loading={isLoading}
        />
      </ContentStyle>
    </LayoutMain>
  );
}

const SearchPanelStyle = styled.div`
  display: flex;
  padding-top: 30px;
  height: 100%;
`;

const ContentStyle = styled.div`
  margin: 24px;
  padding: 24px 32px 32px 32px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(227, 228, 235, 0.25);
`;
const LinkDonwloadStyle = styled.a`
  color: ${theme['@app-link']};
  text-decoration: underline;
`;

const LinkStyle = styled.div`
  text-decoration: underline;
`;

const ModalStyle = {
  Container: styled.div`
    margin: 30px 28px;
  `,

  Title: styled.div`
    margin-bottom: 24px;
    text-align: center;
    font-size: 24px;
  `,

  Content: styled.div`
    margin-bottom: 24px;
  `,
  Desc: styled.div`
    color: #525461;

    margin-bottom: 24px;
  `,
};

export default DigitalCertification;
